import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import Input from '../../../../../reusable/form/Input';
import Select from 'react-select';
import { selectCustomStyle } from '../../../../../../styletheme/custom/selectStyle';
import LoaderView from '../../../../../reusable/loading/loaderView';
import AdvertMultiImageUploader from '../../../../../reusable/uploader/advertMultiImageUploader';
import {
  getPropertyUnitAdvertListingByAdvertId,
  updatePropertyUnitAdvertListing,
} from '../../../../../../services/propertyUnits/advertisment/advertismentManagement';
import { fetchAllCurrencies } from '../../../../../../services/systemDefined/currency/sysCurrencyManagement';
import { getScreeningPacksByUserCreatorId } from '../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement';
import { getUserRentalContractTemplateByUserIdAndPropertyId } from '../../../../../../services/rental_contract/rentalContractService';
import config from '../../../../../../constants/config.json';
import { devLog } from '../../../../../../services/utils/logger';
import { getPropertyByPropertyId } from '../../../../../../services/property/retrieve/propertyRetrievalService';
import { Modal, Button } from 'react-bootstrap';
import PreviewModalContent from '../../../../../reusable/preview/PreviewModalContent';
const UpdateExistingAdvertisement = ({ isDarkMode }) => {
  const { advertId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user_id = localStorage.getItem(config.user_id);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [itemToPreview, setItemToPreview] = useState(null);
  const handleClosePreviewModal = () => setShowPreviewModal(false);

  // State for images
  const [base64Data, setBase64Data] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [existingImages, setExistingImages] = useState([]);

  // Add these state declarations
  const [advertFormData, setAdvertFormData] = useState({
    advert_id: '',
    landlord_id: user_id,
    property_unit_id: '',
    date_advert_ends: new Date().toISOString().split('T')[0],
    campaign_id: '',
    rental_unit_price: 0,
    rental_unit_amt_currency: 'USD',
    security_deposit: 0,
    screening_pack_id: '',
  });

  const [errors, setErrors] = useState({});
  const [screeningPackOptions, setScreeningPackOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedScreeningPack, setSelectedScreeningPack] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);
  const [contractTemplateOptions, setContractTemplateOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedContractTemplate, setSelectedContractTemplate] =
    useState(null);

  const [contractTemplates, setContractTemplates] = useState([
    {
      contract_template_id: '',
      user_id: '',
      portfolio_id: '',
      property_id: '',
      contract_name: '',
      contract_description: '',
      contract_template: '',
      created_at: Date.now(),
      updated_at: Date.now(),
      is_active: true,
      is_deleted: false,
    },
  ]);

  // Add validation schema
  const advertSchema = {
    advert_id: Joi.string().required().label('Advertisement ID'),
    landlord_id: Joi.string().required().label('Landlord ID'),
    property_unit_id: Joi.string().required().label('Property Unit ID'),
    date_advert_ends: Joi.date().required().label('Date Advert Ends'),
    campaign_id: Joi.string().optional().allow('').label('Campaign ID'),
    rental_unit_price: Joi.number().required().label('Rental Amount'),
    rental_unit_amt_currency: Joi.string().required().label('Rental Currency'),
    security_deposit: Joi.number().required().label('Security Deposit'),
    screening_pack_id: Joi.string().optional().label('Screening Pack'),
    property_unit: Joi.object().optional().label('Property Unit'),
    currency_id: Joi.string().optional().allow('').label('Currency ID'),
    is_advert_expired: Joi.boolean().optional().label('Is Advert Expired'),
    created_at: Joi.date().optional().label('Created At'),
    modified_at: Joi.date().optional().label('Modified At'),
    currency_name: Joi.string().optional().allow('').label('Currency Name'),
    contract_template_id: Joi.string()
      .optional()
      .allow('')
      .label('Contract Template'),
    unit_advert_image_urls: Joi.array()
      .items(
        Joi.object({
          url: Joi.string().required(),
          file_name: Joi.string().required(),
          file_size: Joi.number().required(),
          file_size_formatted: Joi.string().required(),
          file_type: Joi.string().required(),
          upload_time_stamp: Joi.string().required(),
          unique_file_name: Joi.string().required(),
        })
      )
      .optional()
      .label('Unit Advert Image URLs'),
  };

  // Add validation functions
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(advertFormData, advertSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: advertSchema[name] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  // Add handlers
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;

      if (name === 'date_advert_ends') {
        try {
          value = new Date(value).toISOString().split('T')[0];
        } catch (error) {
          console.error('Error formatting date:', error);
          value = new Date().toISOString().split('T')[0];
        }
      }
    } else {
      name = 'date_advert_ends';
      value = input;
    }

    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...advertFormData };
    if (value !== undefined) {
      data[name] = value;
    }

    setAdvertFormData(data);
    setErrors(currentErrors);
  };

  const handleCurrencyChange = (option) => {
    setSelectedCurrencyOption(option);
    setAdvertFormData((prev) => ({
      ...prev,
      rental_unit_amt_currency: option.value,
    }));
  };

  const handleScreeningPackChange = (option) => {
    setSelectedScreeningPack(option);
    setAdvertFormData((prev) => ({
      ...prev,
      screening_pack_id: option.value,
    }));
  };

  const handleContractTemplateChange = (option) => {
    setSelectedContractTemplate(option);
    setAdvertFormData((prev) => ({
      ...prev,
      contract_template_id: option.value,
    }));
  };

  // Fetch existing advertisement data
  const fetchAdvertisement = async () => {
    setIsLoading(true);
    try {
      const response = await getPropertyUnitAdvertListingByAdvertId(advertId);
      if (response) {
        devLog(`Advertisement Response:`, response);

        const formattedDate = response.date_advert_ends
          ? new Date(response.date_advert_ends).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0];

        setAdvertFormData({
          ...response,
          date_advert_ends: formattedDate,
          rental_unit_price: response.rental_unit_price || 0,
          rental_unit_amt_currency: response.rental_unit_amt_currency || 'USD',
          security_deposit: response.security_deposit || 0,
          screening_pack_id: response.screening_pack_id || '',
          contract_template_id: response.contract_template_id || '',
        });

        // Handle existing images - simplified to work with URLs directly
        if (
          response.unit_advert_image_urls &&
          response.unit_advert_image_urls.length > 0
        ) {
          // First pass: identify valid image objects
          const validImageUrls = response.unit_advert_image_urls.filter(
            (imageObj) => {
              // Handle case where imageObj is just a string URL
              if (typeof imageObj === 'string') {
                console.warn(`Found string URL instead of object:`, imageObj);
                return false;
              }

              // Check if imageObj is a valid object with required properties
              if (!imageObj || typeof imageObj !== 'object') {
                console.warn(`Found corrupt image object:`, imageObj);
                return false;
              }

              // Verify all required properties exist
              const hasRequiredProps =
                imageObj.file_name &&
                imageObj.url &&
                imageObj.file_type &&
                imageObj.unique_file_name;

              if (!hasRequiredProps) {
                console.warn(
                  `Image object missing required properties:`,
                  imageObj
                );
                return false;
              }

              return true;
            }
          );

          // Update the form data with only valid images
          setAdvertFormData((prev) => ({
            ...prev,
            unit_advert_image_urls: validImageUrls,
          }));

          if (
            validImageUrls.length !== response.unit_advert_image_urls.length
          ) {
            toast.warning(
              `Filtered out ${
                response.unit_advert_image_urls.length - validImageUrls.length
              } corrupt image(s)`
            );
          }

          const existingImageFiles = validImageUrls.map((imageObj) => ({
            name: imageObj.file_name,
            preview: imageObj.url,
            type: imageObj.file_type,
            isExisting: true,
            unique_file_name: imageObj.unique_file_name,
          }));

          setSelectedFiles(existingImageFiles);
          setExistingImages(validImageUrls);
          setBase64Data([]);
          setImagesToUpload({
            unit_advert_image_urls: validImageUrls,
            unit_advert_image_uploads: [],
          });
        }

        // Fetch currencies and screening packs after we have the advertisement data
        const [currencies, screeningPacks] = await Promise.all([
          fetchAllCurrencies(),
          getScreeningPacksByUserCreatorId(user_id),
          fetchRentalContractTemplateByPropertyId(
            response.property_unit.property_id,
            response.property_unit.property_unit_id,
            response.contract_template_id
          ),
        ]);

        // Set currency options
        if (currencies) {
          const currencyOpts = currencies.map((currency) => ({
            value: currency.currency_id,
            label: currency.currency_name,
            data: currency,
          }));
          setCurrencyOptions(currencyOpts);

          // Set selected currency
          if (response.rental_unit_amt_currency) {
            const selectedCurrency = currencyOpts.find(
              (opt) => opt.value === response.currency_id
            );
            if (selectedCurrency) {
              setSelectedCurrencyOption(selectedCurrency);
            }
          }
        }

        // Set screening pack options
        if (screeningPacks) {
          const screeningOpts = screeningPacks.map((pack) => ({
            value: pack.screening_pack_id,
            label: pack.pack_name,
            data: pack,
          }));
          setScreeningPackOptions(screeningOpts);

          // Set selected screening pack
          if (response.screening_pack_id) {
            const selectedPack = screeningOpts.find(
              (opt) => opt.value === response.screening_pack_id
            );
            if (selectedPack) {
              setSelectedScreeningPack(selectedPack);
            }
          }
        }

        // Set contract template if exists
        if (
          response.contract_template_id &&
          contractTemplateOptions.length > 0
        ) {
          const selectedTemplate = contractTemplateOptions.find(
            (opt) => opt.value === response.contract_template_id
          );
          if (selectedTemplate) {
            setSelectedContractTemplate(selectedTemplate);
            setAdvertFormData((prev) => ({
              ...prev,
              contract_template_id: selectedTemplate.value,
            }));
          }
        }
      }
    } catch (error) {
      console.error('Error fetching advertisement:', error);
      toast.error('Failed to load advertisement details');
    }
    setIsLoading(false);
  };

  //get the rental contract template for the property
  const fetchRentalContractTemplateByPropertyId = async (
    _property_id,
    _property_unit_id,
    _contract_template_id
  ) => {
    try {
      const property = await getPropertyByPropertyId(_property_id);
      if (!property) {
        console.error('Property not found');
        return;
      }

      const portfolio_id = property.portfolio_id;

      const response = await getUserRentalContractTemplateByUserIdAndPropertyId(
        _property_id
      );
      if (response && response.length > 0) {
        setContractTemplates(response);
        devLog('Contract Templates:', response);

        // Create options from templates
        const options = response.map((template) => ({
          value: template.contract_template_id,
          label: template.contract_name,
          data: template,
        }));
        setContractTemplateOptions(options);

        // If we have a contract_template_id, find and set the matching template
        if (_contract_template_id) {
          const matchingTemplate = options.find(
            (opt) => opt.value === _contract_template_id
          );
          if (matchingTemplate) {
            devLog('Found matching template:', matchingTemplate);
            setSelectedContractTemplate(matchingTemplate);
            setAdvertFormData((prev) => ({
              ...prev,
              contract_template_id: matchingTemplate.value,
            }));
          }
        }
      } else {
        console.log(
          'No Rental Contract Templates Found, please create a template first'
        );
        toast.error(
          <div>
            No Rental Contract Templates Found, please{' '}
            <a
              href={`/newContractTemplate/${portfolio_id}/${_property_id}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              create a template
            </a>{' '}
            first.
          </div>,
          {
            autoClose: false,
            closeOnClick: true,
            draggable: false,
          }
        );
        return;
      }
    } catch (error) {
      console.error('Error fetching rental contract templates:', error);
      toast.error('Failed to load contract templates');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    setIsLoading(true);
    try {
      const dataToSend = {
        advert_id: advertFormData.advert_id,
        landlord_id: advertFormData.landlord_id,
        property_unit_id: advertFormData.property_unit_id,
        date_advert_ends: advertFormData.date_advert_ends,
        campaign_id: advertFormData.campaign_id || '',
        property_unit: advertFormData.property_unit,
        // Include both existing and new images so the api knows the old state and new one
        unit_advert_image_urls: imagesToUpload.unit_advert_image_urls || [],
        unit_advert_image_uploads:
          imagesToUpload.unit_advert_image_uploads || [],
        security_deposit: Number(advertFormData.security_deposit),
        screening_pack_id: advertFormData.screening_pack_id || '',
        currency_id: selectedCurrencyOption?.value || '',
        currency_name: selectedCurrencyOption?.label || '',
        is_advert_expired: advertFormData.is_advert_expired || false,
        rental_unit_price: Number(advertFormData.rental_unit_price),
        rental_unit_amt_currency: advertFormData.rental_unit_amt_currency,
        contract_template_id: selectedContractTemplate?.value || '',
      };

      devLog('Data to send:', dataToSend);
      const response = await updatePropertyUnitAdvertListing(dataToSend);
      if (response) {
        toast.success('Advertisement Updated Successfully');
        navigate(`/myAdvertisements/${advertFormData.property_unit_id}`);
      } else {
        toast.error('Failed to update Advertisement');
      }
    } catch (error) {
      console.error('Error updating advertisement:', error);
      toast.error('Failed to update Advertisement');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAdvertisement();
  }, [advertId]);

  // Add this useEffect to monitor imagesToUpload changes
  useEffect(() => {
    console.log('Updated imagesToUpload:', imagesToUpload);
  }, [imagesToUpload]);

  // Add the JSX
  return (
    <React.Fragment>
      <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemToPreview && (
            <div className='text-center'>
              <p>{itemToPreview.data?.contract_name}</p>
              <PreviewModalContent
                itemToPreview={itemToPreview?.data?.contract_template}
                height='400px'
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setShowPreviewModal(false);
              setItemToPreview(null);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && <LoaderView />}
      <div
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
        className='page-content'
      >
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle='Update Advertisement'
            previousPageTitle={
              advertFormData.campaign_id
                ? 'Advertisement Campaign'
                : 'My Advertisements'
            }
            previousPageLink={
              advertFormData.campaign_id
                ? `/advertisementCampaign/${advertFormData.campaign_id}`
                : `/myAdvertisements/${advertFormData.property_unit_id}`
            }
          />

          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Update Advertisement
                  </h4>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='date_advert_ends'
                              label='Advert End Date'
                              type='date'
                              value={advertFormData.date_advert_ends}
                              onChange={handleChange}
                              error={errors.date_advert_ends}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='rental_unit_price'
                              label='Rental Amount'
                              type='number'
                              value={advertFormData.rental_unit_price}
                              onChange={handleChange}
                              error={errors.rental_unit_price}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Rental Currency
                            </label>
                            <Select
                              value={selectedCurrencyOption}
                              onChange={handleCurrencyChange}
                              options={currencyOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='security_deposit'
                              label='Security Deposit'
                              type='number'
                              value={advertFormData.security_deposit}
                              onChange={handleChange}
                              error={errors.security_deposit}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Screening Pack
                            </label>
                            <Select
                              value={selectedScreeningPack}
                              onChange={handleScreeningPackChange}
                              options={screeningPackOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Contract Template
                            </label>
                            <div className='d-flex align-items-center gap-2'>
                              <div className='flex-grow-1'>
                                <Select
                                  key={
                                    selectedContractTemplate?.value ||
                                    'contract-template-select'
                                  }
                                  value={selectedContractTemplate}
                                  onChange={handleContractTemplateChange}
                                  options={contractTemplateOptions}
                                  styles={selectCustomStyle(isDarkMode)}
                                />
                              </div>
                              <Button
                                variant='link'
                                className='p-0 text-primary outline-0'
                                disabled={!selectedContractTemplate}
                                onClick={() => {
                                  if (selectedContractTemplate) {
                                    setItemToPreview(selectedContractTemplate);
                                    setShowPreviewModal(true);
                                  }
                                }}
                              >
                                <i className='ri-eye-line fs-5'></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row gy-3 pt-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='form-grid-category'>
                            Unit Images
                          </label>
                          <AdvertMultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText='Update Selection'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex justify-content-between'>
                            <button
                              type='button'
                              className='btn btn-outline-secondary'
                              onClick={() =>
                                navigate(
                                  `/myAdvertisements/${advertFormData.property_unit_id}`
                                )
                              }
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSubmit}
                              className='btn btn-success'
                              type='submit'
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateExistingAdvertisement;
