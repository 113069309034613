import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./components/portal/authentication/login";
import Dashboard from "./components/portal/dashboard/dashboard";
import ProtectedRoute from "./components/portal/authentication/route/protectedroute";
import {
  getCurrentUser,
  getCurrentUserProfileByEmail,
} from "./services/authentication/auth";
import { ToastContainer } from "react-toastify";
import LogOut from "./components/portal/authentication/logout";
import NavigationBar from "./components/portal/navigation/navigationbar";
import MyProperties from "./components/portal/properties/myproperties";
import config from "./constants/config.json";
import PropertyDetail from "./components/portal/properties/propertydetail";
import PropertyUnitDetail from "./components/portal/properties/units/propertunitdetail";
import CreateNewProperty from "./components/portal/properties/form/createnewproperty";
import Signup from "./components/portal/authentication/signup";
import CreatePropertyUnit from "./components/portal/properties/units/create/createPropertyUnit";
import UpdatePropertyUnit from "./components/portal/properties/units/edit/updatePropertyUnit";
import UpdateProperty from "./components/portal/properties/edit/updateProperty";
import UnitPropertyTransactions from "./components/portal/properties/units/transactions/unitPropertyTransactions";
import CreateNewUnitTransaction from "./components/portal/properties/units/transactions/create/createNewUnitTransaction";
import CreateUnitTicket from "./components/portal/tickets/create/createUnitTicket";
import AllUnitTickets from "./components/portal/tickets/list/allUnitTickets";
import TicketDetail from "./components/portal/tickets/detail/ticketDetail";
import EditUnitTicket from "./components/portal/tickets/update/editUnitTicket";
import CreateNewContractor from "./components/portal/contractor/create/createNewContractor";
import ContractorProfileDetail from "./components/portal/contractor/detail/contractorProfileDetail";
import UpdateContractorProfile from "./components/portal/contractor/update/updateContractorProfile";
import CreateNewSubContractor from "./components/portal/contractor/create/subContractor/createNewSubContractor";
import UpdateSubContractor from "./components/portal/contractor/update/subContractor/updateSubContractor";
import AllContractors from "./components/portal/contractor/list/allContractors";
import CreateNewContractTemplate from "./components/portal/rentalContract/create/createNewContractTemplate";
import ContractTemplateDetail from "./components/portal/rentalContract/detail/contractTemplateDetail";
import UpdateContractTemplate from "./components/portal/rentalContract/update/updateContractTemplate";
import AllContractTemplates from "./components/portal/rentalContract/list/allContractTemplates";
import CreateExistingTenant from "./components/portal/lease/tenant/existing/create/createExistingTenant";
import AllTicketsInPortfolio from "./components/portal/tickets/list/portfolio/allTicketsInPortfolio";
import PropertyUnitBankAccounts from "./components/portal/properties/units/bank/list/propertyUnitBankAccounts";
import CreatePropertyUnitBankAccounts from "./components/portal/properties/units/bank/create/createPropertyUnitBankAccounts";
import PropertyBankAccountDetail from "./components/portal/properties/units/bank/detail/propertyBankAccountDetail";
import Paywall from "./components/portal/subscription/paywall/paywall";
import Billing from "./components/portal/subscription/billing/billing";
import {
  getSubscriptionStatusFromStripe,
  getBillingDetailsFromStripe,
  getOrCreateSubscriptionDetail,
} from "./services/authentication/subscription/subscriptionManagementService";

import AllTicketsInProperty from "./components/portal/tickets/list/property/allTicketsInProperty";
import TenantPendingRequest from "./components/portal/tenantScreening/existingTenants/pendingRequest/tenantPendingRequest";
import PendingContractDetail from "./components/portal/tenantScreening/existingTenants/pendingRequest/detail/pendingContractDetail";
import CreateNewAdvertisement from "./components/portal/properties/units/advertisement/create/createNewAdvertisement";
import ScreeningPackList from "./components/portal/tenantScreening/screeningPack/list/screeningPackList";
import CreateNewScreeningPack from "./components/portal/tenantScreening/screeningPack/create/createNewScreeningPack";
import MyAdvertisements from "./components/portal/properties/units/advertisement/list/myAdvertisements";
import AdvertisementDetail from "./components/portal/properties/units/advertisement/detail/advertisementDetail";
import CalendarManagement from "./components/portal/properties/units/advertisement/calendar/calendarManagement";
import ViewingScheduleDetail from "./components/portal/properties/units/advertisement/calendar/viewingScheduleDetail";
import ExpenseDetail from "./components/portal/properties/units/transactions/expenses/detail/expenseDetail";
import RevenueDetail from "./components/portal/properties/units/transactions/revenue/detail/revenueDetail";
import CreateNewUniversalList from "./components/portal/universalTasks/create/createNewUniversalList";
import UniversalList from "./components/portal/universalTasks/list/universalList";
import UniversalListDetail from "./components/portal/universalTasks/detail/universalListDetail";
import TenantApplicantPool from "./components/portal/properties/units/advertisement/prospectives/applicantPool/list/tenantApplicantPool";
import ProspectiveTenantProfileDetail from "./components/portal/properties/units/advertisement/prospectives/applicantPool/detail/prospectiveTenantProfileDetail";
import LandlordPropManagerProfile from "./components/portal/profile/landlordPropManagerProfile";
import { useSettings } from "./services/authentication/contexts/settings/settingsContext";

import { useSubscriptionStatus } from "./context/subscription/SubscriptionStatusContext";
import { useSubscriptionBilling } from "./context/subscription/SubscriptionBillingContext";
import { devLog } from "./services/utils/logger";
import SubscriptionRoute from "./components/portal/subscription/route/SubscriptionRoute";
import AllAdvertisements from "./components/portal/properties/units/advertisement/list/allAdvertisements";
import UpdateExistingAdvertisement from "./components/portal/properties/units/advertisement/update/updateExistingAdvertisement";

//add the add viewing calendar for the property screening into the app
//need to be able to set the calendy if they want to use calendly

//IMPORTANT: need to restrict by if subscripted (view only) and if they are at their unit limit (just cant add more units)
//Any delete feature require a login code sent to the email to confirm the delete

//Plaid Check as an alternative to the credit report (but have credit report as a back up)
//Use the manual tenant screening for the Jamaica rental but can add the identity verification if it works there
function App() {
  const { settings, setSettings } = useSettings();
  const isDarkMode = settings.isDarkMode;
  const [userToken, setUserToken] = useState("");

  const { subscriptionStatus, setSubscriptionStatusState } =
    useSubscriptionStatus();
  const { billingDetails, setBillingDetails } = useSubscriptionBilling();

  //get and set the landlord profile (use redux to update this)
  const [userProfile, setUserProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    profileImg: "",
    stripeCustomerId: "",
  });

  //const { settings, setSettings } = useSettings();

  //const isDarkMode = settings.isDarkMode;

  //get the subscription status details
  const getSubscriptionDetails = async () => {
    const subscriptionDetails = await getOrCreateSubscriptionDetail();

    if (!subscriptionDetails) return null;

    devLog("Subscription Details:", subscriptionDetails);

    setSubscriptionStatusState(subscriptionDetails);
  };

  useEffect(() => {
    //dark mode start
    const theme = isDarkMode ? "dark" : "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
    //dark mode end

    getUserToken();

    getSubscriptionDetails();
  }, [isDarkMode]);

  const getUserToken = async () => {
    try {
      const userToken = await getCurrentUser();

      if (!userToken) return null;

      setUserToken({ userToken });

      //localStorage.setItem(config.access_token, userToken);
      //it means the person is logged in
      getLoggedInUserProfile();
    } catch (error) {
      console.log(`Error getting user token: ${error}`);
      // Handle the error appropriately here
    }
  };

  const getLoggedInUserProfile = async () => {
    const userProfile = await getCurrentUserProfileByEmail();

    if (!userProfile) return null;

    //set if userProfile.user_id not empty
    if (
      userProfile.userId !== "" &&
      userProfile.userId !== null &&
      userProfile.userId !== undefined
    ) {
      localStorage.setItem(config.user_id, userProfile.userId);
    }

    if (
      userProfile.firstName !== "" &&
      userProfile.firstName !== null &&
      userProfile.firstName !== undefined
    ) {
      localStorage.setItem(config.first_name, userProfile.firstName);
    }

    if (
      userProfile.lastName !== "" &&
      userProfile.lastName !== null &&
      userProfile.lastName !== undefined
    ) {
      localStorage.setItem(config.last_name, userProfile.lastName);
    }

    if (
      userProfile.profileImg !== "" &&
      userProfile.profileImg !== null &&
      userProfile.profileImg !== undefined
    ) {
      localStorage.setItem(config.profile_img, userProfile.profileImg);
    }

    if (
      userProfile.stripeCustomerId !== "" &&
      userProfile.stripeCustomerId !== null &&
      userProfile.stripeCustomerId !== undefined
    ) {
      localStorage.setItem(
        config.stripe_customer_id,
        userProfile.stripeCustomerId
      );
    }

    setUserProfile({
      userId: userProfile.userId,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      emailAddress: userProfile.emailAddress,
      profileImg: userProfile.profileImg,
      stripeCustomerId: userProfile.stripeCustomerId,
    });

    // Check if stripeCustomerId exists before calling subscription-related functions
    if (userProfile.stripeCustomerId) {
      //check the subscription
      const subscriptionSuccess = await fetchSubscriptionStatus();
      if (subscriptionSuccess) {
        //check the last payment status
        await findBillingDetails();
      }
    }
    //check if there is any pending pal request
    //findAllPendingPalRequests();
  };

  const findBillingDetails = async () => {
    const stripeCustomerId = localStorage.getItem(config.stripe_customer_id);
    const billingDetails = await getBillingDetailsFromStripe(stripeCustomerId);

    if (!billingDetails) return null;

    //set the payment status
    //console.log('Payment Status');
    devLog(`billingDetails: ${billingDetails}`);

    setBillingDetails(billingDetails);

    //console.log(paymentStatus);
  };

  const fetchSubscriptionStatus = async () => {
    //get the current subscription
    const subscriptionStatus = await getSubscriptionStatusFromStripe();

    devLog(`subscriptionStatus: `);
    devLog(subscriptionStatus);

    if (!subscriptionStatus) return false;

    //update the subscriptionStatusContext
    setSubscriptionStatusState(subscriptionStatus);

    return true;
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <div id="layout-wrapper">
          {/*<NavBar /> need a protected view only show the nav when logged in*/}
          <div className="navbar-header">
            <NavigationBar userProfile={userProfile} isDarkMode={isDarkMode} />
          </div>

          <div className="vertical-overlay"></div>
          <div className="layout-width">
            <Routes>
              {/*<Route path='/' element={<LoginPage />} /> */}
              <Route path="/" element={<LoginPage isDarkMode={isDarkMode} />}>
                <Route
                  path=":loginCode"
                  element={<LoginPage isDarkMode={isDarkMode} />}
                />
              </Route>

              {/*<Route path='/signup' element={<Signup />} />*/}

              <Route
                path="/signup"
                element={<Signup isDarkMode={isDarkMode} />}
              >
                <Route
                  path=":inviteCode"
                  element={<Signup isDarkMode={isDarkMode} />}
                />
              </Route>

              {/*<ProtectedRoute
							user={userToken}
							path='/dashboard'
							element={<Dashboard />}
  />*/}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Dashboard
                      userProfile={userProfile}
                      isDarkMode={isDarkMode}
                    />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <LandlordPropManagerProfile isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/myproperties"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <MyProperties
                        userProfile={userProfile}
                        isDarkMode={isDarkMode}
                      />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newproperty"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <CreateNewProperty
                        userProfile={userProfile}
                        isDarkMode={isDarkMode}
                      />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newUnit"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<CreatePropertyUnit isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/editUnit"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <UpdatePropertyUnit isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<UpdatePropertyUnit isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/unitTransactions"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <UnitPropertyTransactions isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<UnitPropertyTransactions isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/newUnitTransaction"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <CreateNewUnitTransaction isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateNewUnitTransaction isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/propertyUnitBankAccounts"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <PropertyUnitBankAccounts isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<PropertyUnitBankAccounts isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/newAdvertisement"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <CreateNewAdvertisement isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateNewAdvertisement isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/myAdvertisements"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <MyAdvertisements isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={<MyAdvertisements isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/allAdvertisements"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <AllAdvertisements isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/createPropertyUnitBankAccounts"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <CreatePropertyUnitBankAccounts isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={
                    <CreatePropertyUnitBankAccounts isDarkMode={isDarkMode} />
                  }
                />
              </Route>
              <Route
                path="/bankAccountDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyBankAccountId"
                  element={
                    <PropertyBankAccountDetail isDarkMode={isDarkMode} />
                  }
                />
              </Route>

              <Route
                path="/advertisementCampaign"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<AdvertisementDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/unitViewingCalendar"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<CalendarManagement isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/unitViewingSchedule"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <ViewingScheduleDetail isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":campaignId"
                  element={<ViewingScheduleDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/expenseDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":unitExpenseId"
                  element={<ExpenseDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/revenueDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":unitRevenueId"
                  element={<RevenueDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/createUniversalList"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<CreateNewUniversalList isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/universalList"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<UniversalList isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/universalListDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":universalListId"
                  element={<UniversalListDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              {/* PropertyUnitDetail	<Route
								path='/property'
								element={<PropertyDetail landlordProfile={landlordProfile} />}>
								<Route
									path=':propertyId'
									element={
										<ProtectedRoute user={userToken} redirectPath='/'>
											<PropertyDetail landlordProfile={landlordProfile} />
										</ProtectedRoute>
									}
								/>
							</Route>*/}

              <Route
                path="/allUnitTickets"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<AllUnitTickets isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/allPropertyTickets"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<AllTicketsInProperty isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/newUnitTicket"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <CreateUnitTicket isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId?"
                  element={<CreateUnitTicket isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/editUnitTicket"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":ticketId"
                  element={<EditUnitTicket isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/ticket"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":ticketId"
                  element={<TicketDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/property"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={
                    <PropertyDetail
                      landlordProfile={userProfile}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
              </Route>
              <Route
                path="/updateProperty"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyId"
                  element={<UpdateProperty isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/propertyunit"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <PropertyUnitDetail
                        userProfile={userProfile}
                        isDarkMode={isDarkMode}
                      />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":propertyUnitId"
                  element={
                    <PropertyUnitDetail
                      userProfile={userProfile}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
              </Route>
              <Route
                path="/createContractor"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <CreateNewContractor isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contractors"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <AllContractors isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newContractTemplate"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <CreateNewContractTemplate isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newContractTemplate/:portfolio_id/:property_id"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <CreateNewContractTemplate isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contractTemplate"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractTemplateId"
                  element={<ContractTemplateDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateContractTemplate"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractTemplateId"
                  element={<UpdateContractTemplate isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contractTemplates"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <SubscriptionRoute>
                      <AllContractTemplates isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/createExistingTenant"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<CreateExistingTenant isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<ContractorProfileDetail isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<UpdateContractorProfile isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/createSubContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":contractorId"
                  element={<CreateNewSubContractor isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/updateSubContractor"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":subContractorId"
                  element={<UpdateSubContractor isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/applicantPool"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":campaignId"
                  element={<TenantApplicantPool isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/applicantPoolDetail"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":tenantId"
                  element={
                    <ProspectiveTenantProfileDetail isDarkMode={isDarkMode} />
                  }
                />
              </Route>

              <Route
                path="/allTicketsInPortfolio"
                element={
                  <ProtectedRoute user={userToken}>
                    <SubscriptionRoute>
                      <AllTicketsInPortfolio isDarkMode={isDarkMode} />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/paywall"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Paywall isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/billing"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <Billing isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pendingExistingTenantRequest"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":propertyUnitId"
                  element={<TenantPendingRequest isDarkMode={isDarkMode} />}
                />
              </Route>
              <Route
                path="/contract"
                element={<ProtectedRoute user={userToken} />}
              >
                <Route
                  path=":rentalContractId"
                  element={<PendingContractDetail isDarkMode={isDarkMode} />}
                />
              </Route>

              <Route
                path="/screeningPacks"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <ScreeningPackList isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/createScreeningPack"
                element={
                  <ProtectedRoute user={userToken} redirectPath="/">
                    <CreateNewScreeningPack isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/updateAdvertisement/:advertId"
                element={
                  <ProtectedRoute>
                    <UpdateExistingAdvertisement isDarkMode={isDarkMode} />
                  </ProtectedRoute>
                }
              />

              <Route path="/logout" element={<LogOut />} />
            </Routes>

            {/*	<GeneralFooter />*/}
            <footer className="footer ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <script>document.write(new Date().getFullYear())</script> ©
                    Gurenter Landlord.
                  </div>
                  <div className="col-sm-6">
                    <div className="text-sm-end d-none d-sm-block">
                      Designed & Developed by Gurenter LLC
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
