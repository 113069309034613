import React, { useState, useCallback, useEffect } from 'react';
import { X, FileText } from 'lucide-react';

const IMAGE_DIMENSIONS = { width: '100px', height: '100px' };
const BUTTON_DIMENSIONS = { width: '24px', height: '24px' };
const FILE_TYPES = { IMAGE: 'image/', PDF: '.pdf' };

export const PreviewFileItem = React.memo(({ file, index, onRemove }) => {
  const [preview, setPreview] = useState(file.preview || null);
  const [isCorrupt, setIsCorrupt] = useState(false);

  const handleFilePreview = useCallback(() => {
    if (
      !file.preview &&
      file instanceof File &&
      file.type.startsWith(FILE_TYPES.IMAGE)
    ) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.onerror = () => {
        console.error('Error reading file:', file.name);
        setIsCorrupt(true);
        onRemove(index, new Event('corrupt'));
      };
      reader.readAsDataURL(file);
    }
  }, [file, index, onRemove]);

  useEffect(() => {
    handleFilePreview();
    return () => {
      if (preview && !file.isExisting) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [file, handleFilePreview, preview]);

  const handleImageError = useCallback(() => {
    console.error('Error loading image:', file);
    setIsCorrupt(true);
    onRemove(index, new Event('corrupt'));
  }, [file, index, onRemove]);

  if (isCorrupt) return null;

  return (
    <div className='position-relative' style={IMAGE_DIMENSIONS}>
      {file.type?.startsWith(FILE_TYPES.IMAGE) || file.isExisting ? (
        preview && (
          <img
            src={preview}
            alt={`Preview ${index}`}
            className='w-100 h-100 object-fit-cover rounded'
            onError={handleImageError}
          />
        )
      ) : (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded'>
          <FileText size={32} />
        </div>
      )}
      <button
        onClick={(e) => onRemove(index, e)}
        className='btn btn-danger btn-sm position-absolute top-0 end-0 rounded-circle p-0 d-flex align-items-center justify-content-center'
        style={BUTTON_DIMENSIONS}
        aria-label='Remove file'
      >
        <X size={14} style={{ margin: 'auto' }} />
      </button>
    </div>
  );
});
