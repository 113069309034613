import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


import PageTitleBox from '../../../reusable/title/pageTitleBox';
import { formatDistanceToNow, set } from 'date-fns';
import userAvatar from '../../../../styletheme/images/users/user-dummy-img.jpg';

import Select from 'react-select';
import companyImg from '../../../../styletheme/images/companies/img-4.png';

import 'simplebar'; // for the side effects
import 'simplebar/dist/simplebar.min.css';
import {
  assignTicketToUser,
  createTicketComment,
  deleteTicketAttachment,
  getTicketCommentsByTicketId,
  getUnitTicketByTicketId,
  getUsersAssignedToTicket,
  updateUnitTicketPriority,
  updateUnitTicketStatus,
  uploadTicketAttachments,
} from '../../../../services/tickets/unitTicketsManagement';
import { getPropertyUnitByPropertyUnitId } from '../../../../services/propertyUnits/propertyUnitManagement';
import { fetchAllPriorities } from '../../../../services/systemDefined/priority/sysPriorityManagement';
import { fetchAllStatuses } from '../../../../services/systemDefined/status/sysStatusManagemetn';
import { getPropertyByPropertyId } from '../../../../services/property/retrieve/propertyRetrievalService';
import { toast } from 'react-toastify';

import HTMLEditor from '../../../reusable/form/ckeditor/cKEditorComponent';
import config from '../../../../constants/config.json';
import { v4 as uuid } from 'uuid';
import LoaderView from '../../../reusable/loading/loaderView';
import { Modal, Button } from 'react-bootstrap';
import {
  getContractorsByPortfolioId,
  getContractorsByPropertyId,
} from '../../../../services/contractor/contractorManagement';
import profileImg from '../../../../styletheme/images/user-icon/avatar.png';
import MultiImageUploader from '../../../reusable/uploader/multiImageUploader';
import FilePreview from './sub/filePreview';
import FilePDFImagePreview from './sub/filePdfImagePreview';
import { selectCustomStyle } from '../../../../styletheme/custom/selectStyle';

//TODO: add the file upload control here next
//todo when you click an assignee it opens a model where they have the option to remove them
const TicketDetail = ({ isDarkMode }) => {
  const { ticketId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const pageTitle = 'Ticket Detail';
  const [editorLoaded, setEditorLoaded] = useState(false);
  //used to navigate to another page
  const [property_unit_id, setPropertyUnitId] = useState('');
  const [ticket_priority, setTicketPriority] = useState('');
  const [ticketData, setTicketData] = useState({
    ticket_id: '',
    property_unit_id: '',
    ticket_type: '',
    ticket_title: '',
    ticket_description: '',
    ticket_priority_id: '',
    ticket_status_id: '',
    ticket_number: '',
    ticket_status: '',
    created_at: '',
    updated_at: '',
    ticket_media_urls: [
      {
        file_name: '',
        file_size: 0,
        file_size_formatted: '',
        file_type: '',
        url: '',
      },
    ],
  });

  const [property, setProperty] = useState({
    property_id: '',
    portfolio_id: '',
    currency_id: '',
    property_type_id: '',
    sub_property_type_id: '',
    property_alias_name: '',
    street_address_1: '',
    street_address_2: '',
    city_town: '',
    state_parish: '',
    zip_postal_code: '',
    country_id: '',
    country: '',
  });

  const [unit, setUnit] = useState({
    market_rent_amount: '',
    number_of_bathrooms: '',
    number_of_bedrooms: '',
    property_id: '',
    property_unit_id: '',
    square_footage: '',
    unit_name: '',
  });

  //contractors
  const [contractors, setContractors] = useState([
    {
      contractor_id: '',
      portfolio_id: '',
      property_id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      company_name: '',
      company_address: '',
      company_address_2: '',
      company_city: '',
      company_state: '',
      company_zip: '',
      company_country: '',
      company_logo: '',
      company_website: '',
      company_phone: '',
      company_email: '',
      company_description: '',
      company_license: '',
      company_insurance: '',
      company_insurance_expiration: new Date(),
      company_first_licensure_date: new Date(),
      company_license_expiration: new Date(),
      company_license_status: '', //nt used
      company_tax_rate: 0,
      company_tax_type: '', //not used
      payment_rate_type: '', //not used
      payment_rate_other: '', //not used
      payment_rate: 0, //not used need to use the payment rate type
      payment_rate_frequency: '',
      company_tax_id: '',
      payment_rate_method: '',
      created_at: new Date(),
      updated_at: new Date(),
      is_active: true,
      is_verified: false,
      is_deleted: false,
      is_suspended: false,
      is_paused: false,
      job_count: 0,
      job_count_completed: 0,
      company_license_state: '',
    },
  ]);

  const [usersAssignedToTicket, setUsersAssignedToTicket] = useState([]);

  //new comment
  const [newComment, setNewComment] = useState('');

  const [ticketPriorities, setTicketPriorities] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [ticketStatuses, setTicketStatuses] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [ticketTypesSelected, setTicketTypesSelected] = useState(null);
  const [ticketPrioritiesSelected, setTicketPrioritiesSelected] =
    useState(null);
  const [ticketStatusesSelected, setTicketStatusesSelected] = useState(null);

  const [comments, setComments] = useState([
    {
      ticket_comment_id: '',
      ticket_id: '',
      user_id: '',
      comment: '',
      created_by: '',
      created_at: new Date(),
      updated_at: new Date(),
    },
  ]);

  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'New':
        return 'bg-info';
      case 'In Progress':
        return 'bg-warning';
      case 'Pending':
        return 'bg-primary';
      case 'Completed':
        return 'bg-success';
      default:
        return 'bg-info';
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-info';
      case 'Medium':
        return 'bg-warning';
      case 'High':
        return 'bg-danger';
      default:
        return 'bg-info';
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    fetchTicketData();
  }, []);

  const fetchTicketData = async () => {
    //get the ticket details by ticket_id]
    await fetchUnitTicketById();
    await fetchUsersAssignedToTicket();
    setIsAttachmentsLoading(false); // Ensure loading state is reset after fetching data
  };

  const fetchUnitTicketById = async () => {
    //get the ticket details by ticket_id

    console.log(ticketId);
    const ticketData = await getUnitTicketByTicketId(ticketId);

    //ensure the ticket data is not empty
    if (ticketData === undefined || ticketData === null) {
      setIsLoading(false);
      return;
    }

    setTicketData(ticketData);

    console.log(ticketData);

    //set the property_unit_id
    if (ticketData.property_unit_id !== undefined) {
      setPropertyUnitId(ticketData.property_unit_id);
      getUnitDetails(ticketData.property_unit_id);

      //get all priorities
      getAllPriorities(ticketData);

      //get all statuses
      getAllStatuses(ticketData);

      //get all comments
      getAllComments(ticketData.ticket_id);

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getAllPriorities = async (_ticketData) => {
    const result = await fetchAllPriorities();
    console.log(result);
    const priorities = result.map((p) => {
      return { value: p.priority_id, label: p.priority, data: p };
    });

    setTicketPriorities(priorities);

    //set the default priority
    const defaultPriority = priorities.find(
      (p) => p.value === _ticketData.ticket_priority_id
    );

    console.log(defaultPriority);
    if (defaultPriority) {
      setTicketPrioritiesSelected(defaultPriority);
      console.log(defaultPriority);

      setTicketPriority(defaultPriority.label);
    }
  };

  //get all statuses
  const getAllStatuses = async (_ticketData) => {
    const result = await fetchAllStatuses();
    console.log(result);
    const statuses = result.map((s) => {
      return { value: s.status_id, label: s.status, data: s };
    });

    setTicketStatuses(statuses);

    //set the default status
    const defaultStatus = statuses.find(
      (s) => s.value === _ticketData.ticket_status_id
    );
    if (defaultStatus) {
      setTicketStatusesSelected(defaultStatus);
    }
  };

  //get the unit details
  const getUnitDetails = async (_property_unit_id) => {
    //get the unit details
    const unit = await getPropertyUnitByPropertyUnitId(_property_unit_id);

    console.log('Unit Details');
    console.log(unit);
    setUnit(unit);

    //get the property details
    if (unit) {
      getPropertyProfile(unit.property_id);
    }
  };

  const getPropertyProfile = async (_propertyId) => {
    const property = await getPropertyByPropertyId(_propertyId);

    if (property) {
      console.log(property);
      setProperty(property);

      getAllContractors(property.property_id, property.portfolio_id);
    }
  };

  //get all contractors for property
  const getAllContractors = async (_property_id, _portfolio_id) => {
    //try to get the getContractorsByPropertyId if the property_id is available
    if (_property_id) {
      //get all contractors for the property
      const result = await getContractorsByPropertyId(_property_id);

      if (result) {
        setContractors(result);

        //set the contractorOptions
        const contractorOptions = result.map((c) => {
          return {
            value: c.contractor_id,
            label: `${c.first_name} ${c.last_name}`,
            data: c,
          };
        });
        setContractorOptions(contractorOptions);

        console.log('Contractors');
        console.log(contractorOptions);
      }
    } else if (_portfolio_id) {
      //get all contractors for the portfolio
      const result = await getContractorsByPortfolioId(_portfolio_id);

      if (result) {
        setContractors(result);

        //set the contractorOptions
        const contractorOptions = result.map((c) => {
          return {
            value: c.contractor_id,
            label: `${c.first_name} ${c.last_name}`,
            data: c,
          };
        });
        setContractorOptions(contractorOptions);

        console.log('Contractors');
        console.log(contractorOptions);
      }
    }
  };

  //contractor options
  const [contractorOptions, setContractorOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [selectedContractor, setSelectedContractor] = useState({
    value: '',
    label: '',
    data: {},
  });

  const handleContractorChange = (selectedContractor) => {
    setSelectedContractor(selectedContractor);
  };

  const handleTicketStatusChange = async (ticketStatusesSelected) => {
    setTicketStatusesSelected(ticketStatusesSelected);

    //if different from the current ticket status, update the ticket status on the server then update the ticket status on the UI
    if (ticketStatusesSelected.value !== ticketData.ticket_status_id) {
      //update the ticket status on the server
      const response = await updateUnitTicketStatus(
        ticketData.ticket_id,
        ticketStatusesSelected.value,
        ticketStatusesSelected.label
      );

      if (response) {
        //update the ticket status on the UI
        setTicketData((prevState) => ({
          ...prevState,
          ticket_status_id: ticketStatusesSelected.value,
          ticket_status: ticketStatusesSelected.label,
        }));

        //set the ticket status for the ticket status dropdown
        setTicketStatusesSelected(ticketStatusesSelected);

        toast.success('Ticket status updated successfully');
      } else {
        toast.error('Ticket status update failed');
      }
    }
  };

  const handleTicketPriorityChange = async (ticketPrioritiesSelected) => {
    setTicketPrioritiesSelected(ticketPrioritiesSelected);

    //if different from the current ticket priority, update the ticket priority on the server then update the ticket priority on the UI
    if (ticketPrioritiesSelected.value !== ticketData.ticket_priority_id) {
      //update the ticket priority on the server
      const response = await updateUnitTicketPriority(
        ticketData.ticket_id,
        ticketPrioritiesSelected.value,
        ticketPrioritiesSelected.label
      );

      if (response) {
        //update the ticket priority on the UI
        setTicketData((prevState) => ({
          ...prevState,
          ticket_priority_id: ticketPrioritiesSelected.value,
        }));

        //set the ticket priority for the ticket priority dropdown
        setTicketPrioritiesSelected(ticketPrioritiesSelected);

        setTicketPriority(ticketPrioritiesSelected.label);

        toast.success('Ticket priority updated successfully');
      } else {
        toast.error('Ticket priority update failed');
      }
    }
  };

  //handleEditorChange
  const handleEditorChange = (content) => {
    setNewComment(content);
  };

  //submit the new comment
  const submitNewComment = async () => {
    //create a new comment

    const user_id = localStorage.getItem(config.user_id);
    const user_name =
      localStorage.getItem(config.first_name) +
      ' ' +
      localStorage.getItem(config.last_name);
    const data = {
      ticket_comment_id: uuid(),
      ticket_id: ticketData.ticket_id,
      user_id: user_id,
      comment: newComment,
      created_by: user_name,
      created_at: new Date(),
      updated_at: new Date(),
    };

    console.log(data);

    const response = await createTicketComment(data);

    if (response) {
      toast.success('Comment posted successfully');

      //clear the new comment
      setNewComment('');

      setIsLoading(true);
      //reload the ticket comments
      fetchUnitTicketById(ticketData.ticket_id);
    }
  };

  //get all comments for the ticket
  const getAllComments = async (_ticket_id) => {
    //get all comments for the ticket

    const ticketComments = await getTicketCommentsByTicketId(_ticket_id);

    if (ticketComments) {
      ticketComments.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      setComments(ticketComments);

      console.log('Ticket Comments');
      console.log(ticketComments);
    }
  };

  //for the modal
  //may put in own file later
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [errorsForEdit, setErrorsForEdit] = useState({});

  //attachment modal
  const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
  const handleCloseAddAttachmentModal = () => setShowAddAttachmentModal(false);
  //attachments section start
  const [base64Data, setBase64Data] = useState([]);
  //images to upload for the advertisement
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  //attachments section end
  //preview modal
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleClosePreviewModal = () => setShowPreviewModal(false);
  const [itemToPreview, setItemToPreview] = useState({
    file_name: '',
    file_size: 0,
    file_size_formatted: '',
    file_type: '',
    url: '',
  });

  //confirm delete of the attachment
  const [showConfirm, setShowConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({
    file_name: '',
    file_size: 0,
    file_size_formatted: '',
    file_type: '',
    url: '',
  });

  const handleDelete = async () => {
    // Perform the delete action here
    console.log('Item to delete ' + itemToDelete.unique_file_name);

    if (!itemToDelete.unique_file_name) {
      toast.error('Attachment delete failed');
      return;
    }

    setIsAttachmentsLoading(true);

    const payload = {
      ticket_id: ticketId,
      unique_file_name: itemToDelete.unique_file_name,
    };

    const response = await deleteTicketAttachment(payload);

    if (response) {
      if (response.error) {
        toast.error(response.error);
        setIsAttachmentsLoading(false);
        return;
      }
      toast.success('Attachment deleted successfully');
      //reload the ticket
      await fetchTicketData();
    } else {
      toast.error('Attachment delete failed');
      setIsAttachmentsLoading(false);
    }

    //close the modal
    setShowConfirm(false);
    handleClosePreviewModal();
  };

  //edit the property unit from the list of property units
  const handleEditPropertyUnit = () => {};

  const handleSaveChanges = () => {
    //assign the ticket to the selected contractor
    const data = {
      user_assigned_ticket_id: '', //api assigned
      ticket_id: ticketId,
      user_id: '',
      contractor_id: selectedContractor.value,
      assigned_by: localStorage.getItem(config.first_name),
      assigned_at: new Date(),
      updated_at: new Date(),
    };

    console.log(data);

    const response = assignTicketToUser(data);

    if (response) {
      toast.success('Ticket assigned to contractor successfully');

      //fetch the latest getUsersAssignedToTicket
      fetchUsersAssignedToTicket();
      //close the modal
      setShowModal(false);
    }
  };

  //ticket_media_uploads
  //uploading attachments
  const handleAttachmentUpload = async () => {
    setIsAttachmentsLoading(true);
    const attachmentsToUpload = {
      ticket_id: ticketId,
      ticket_media_uploads: imagesToUpload,
    };

    console.log(attachmentsToUpload);

    //upload the attachments
    const response = await uploadTicketAttachments(attachmentsToUpload);

    if (response) {
      toast.success('Attachments uploaded successfully');
      //close the modal
      setShowAddAttachmentModal(false);
    }

    //clear the attachments
    setBase64Data([]);
    setSelectedFiles([]);
    setImagesToUpload([]);

    //reload the ticket
    await fetchTicketData();
  };

  const cancelAttachmentUpload = () => {
    //clear the attachments
    setBase64Data([]);
    setSelectedFiles([]);
    setImagesToUpload([]);
    //close the modal
    setShowAddAttachmentModal(false);
  };

  const fetchUsersAssignedToTicket = async () => {
    //get all users assigned to the

    const result = await getUsersAssignedToTicket(ticketId);

    if (result) {
      //need to update the UI when its newly added
      setUsersAssignedToTicket(result);

      console.log('Users Assigned to Ticket');
      console.log(result);
    }
  };

  return (
    <React.Fragment>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setItemToDelete({
                file_name: '',
                file_size: 0,
                file_size_formatted: '',
                file_type: '',
                url: '',
              });
              setShowConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assign User to Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row gy-4'>
            <div className='col-xxl-12 col-md-12'>
              <div>
                <label className='form-label'>Contractor</label>
                <Select
                  name='contractor_id'
                  options={contractorOptions}
                  value={selectedContractor}
                  onChange={handleContractorChange}
                  styles={selectCustomStyle(isDarkMode)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant='success'
            onClick={() => {
              handleSaveChanges();
            }}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddAttachmentModal}
        onHide={() => {
          cancelAttachmentUpload();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adding New Attachment(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row gy-4'>
            <div className='col-xxl-12 col-md-12'>
              <div>
                <label className='form-label'>Images and Attachments</label>
                <MultiImageUploader
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  base64Data={base64Data}
                  setBase64Data={setBase64Data}
                  imagesToUpload={imagesToUpload}
                  setImagesToUpload={setImagesToUpload}
                  uploadBtnText='Confirm Selection'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              cancelAttachmentUpload();
            }}
          >
            Close
          </Button>
          <Button
            variant='success'
            disabled={imagesToUpload.length < 1}
            onClick={() => {
              handleAttachmentUpload();
            }}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPreviewModal} onHide={handleClosePreviewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilePDFImagePreview
            itemToPreview={itemToPreview}
            setItemToDelete={setItemToDelete}
            setShowConfirm={setShowConfirm}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setShowPreviewModal(false);
              setItemToPreview({
                file_name: '',
                file_size: 0,
                file_size_formatted: '',
                file_type: '',
                url: '',
              });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading && <LoaderView />}
      <div
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
        className='page-content'
      >
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='Unit Tickets'
            previousPageLink={`/allUnitTickets/${property_unit_id}`}
          />

          <div className='row pt-3 px-4 '>
            <div className='col-lg-12'>
              <div className='card mt-n4 mx-n4 mb-n5'>
                <div className='bg-warning-subtle'>
                  <div className='card-body pb-4 mb-5'>
                    <div className='row'>
                      <div className='col-md'>
                        <div className='row align-items-center'>
                          <div className='col-md-auto'>
                            <div className='avatar-md mb-md-0 mb-4'>
                              <div className='avatar-title bg-white rounded-circle'>
                                <img
                                  src={companyImg}
                                  alt=''
                                  className='avatar-sm'
                                />
                              </div>
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-md'>
                            <h4 className='fw-semibold' id='ticket-title'>
                              #{ticketData.ticket_number} -{' '}
                              {ticketData.ticket_title}
                            </h4>
                            <div className='hstack gap-3 flex-wrap'>
                              <div className='text-muted'>
                                <i className='mdi mdi-format-list-bulleted-type align-bottom me-1'></i>
                                <span id='ticket-client'>
                                  {ticketData.ticket_type}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Create Date :{' '}
                                <span className='fw-medium ' id='create-date'>
                                  {new Date(
                                    ticketData.created_at
                                  ).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Last Updated :{' '}
                                <span className='fw-medium' id='due-date'>
                                  {new Date(
                                    ticketData.created_at
                                  ).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div
                                className={`badge rounded-pill fs-12 ${getStatusBackgroundColor(
                                  ticketData.ticket_status
                                )}`}
                                id='ticket-status'
                              >
                                {ticketData.ticket_status}
                              </div>
                              <div
                                className={`badge rounded-pill fs-12 ${getPriorityColor(
                                  ticket_priority
                                )}`}
                                id='ticket-priority'
                              >
                                {ticket_priority}
                              </div>
                            </div>
                          </div>
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end col*/}
                      <div className='col-md-auto mt-md-0 mt-4'>
                        <div className='hstack gap-1 flex-wrap'>
                          <button
                            type='button'
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setIsLoading(true);
                              fetchUnitTicketById();
                            }}
                            className='btn py-0 fs-16 text-body material-shadow-none'
                          >
                            <i className='bx bx-rotate-left'></i>
                            <span className='d-none d-sm-inline'>Ticket</span>
                          </button>
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </div>
                  {/* end card body */}
                </div>
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}

          <div className='row'>
            <div className='col-xxl-9'>
              <div className='card'>
                <div className='card-body p-4'>
                  <h6 className='fw-semibold text-uppercase mb-3'>
                    Ticket Description
                  </h6>
                  <p className='text-muted'>{ticketData.ticket_description}</p>
                </div>
                {/*end card-body*/}
                <div className='card-body p-4'>
                  <h5 className='card-title mb-4'>Comments</h5>

                  <div
                    data-simplebar='true'
                    style={{ height: '300px' }}
                    className='px-3 mx-n3'
                  >
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      {comments && comments.length > 0 ? (
                        comments.map((comment, index) => (
                          <li key={index}>
                            <div className='d-flex mb-4'>
                              <div className='flex-shrink-0'>
                                <img


                                  src={
                                    comment.user_profile_image_url
                                      ? comment.user_profile_image_url
                                      : userAvatar
                                  }
                                  style={{ width: '35px', height: '35px' }}

                                  alt=''
                                  className='avatar-xs rounded-circle material-shadow'
                                />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <h5 className='fs-13'>

                                  {comment.user_name}{' '}

                                  <small className='text-muted'>
                                    {comment.updated_at
                                      ? new Intl.DateTimeFormat('default', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          second: '2-digit',
                                          hour12: true,
                                        }).format(new Date(comment.updated_at))
                                      : 'No date'}
                                  </small>
                                </h5>
                                <p
                                  className='text-muted'
                                  dangerouslySetInnerHTML={{
                                    __html: comment.comment,
                                  }}
                                ></p>
                                <a
                                  href='javascript: void(0);'
                                  className='badge text-muted bg-light'
                                  hidden
                                >
                                  <i className='mdi mdi-reply'></i> Reply
                                </a>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div className='d-flex mb-4'>
                          <p>No comments available</p>
                        </div>
                      )}
                    </ul>
                  </div>
                  <div>
                    <div className='row g-3 pt-3'>
                      <div className='col-lg-12'>
                        <div className='row'>
                          <div className='col-md-8 d-flex flex-column'>
                            <label
                              for='exampleFormControlTextarea1'
                              className='form-label'
                            >
                              Leave a Comment
                            </label>

                            <HTMLEditor
                              handleChange={handleEditorChange}
                              name='newComment'
                              content={newComment}
                            />
                            <div className='d-flex justify-content-end mt-3 pt-4'>
                              <a
                                onClick={() => {
                                  submitNewComment();
                                }}
                                className='btn btn-success btn-md mt-2 ml-auto'
                              >
                                Post Comment
                              </a>
                            </div>
                          </div>
                          {newComment && newComment !== '' && (
                            <div className='col-md-4'>
                              <label
                                for='exampleFormControlTextarea1'
                                className='form-label'
                              >
                                Preview
                              </label>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: newComment.replace(
                                    /<img /g,
                                    '<img style="max-width: 100%; height: auto;" '
                                  ),
                                }}
                                style={{
                                  padding: '10px',
                                  border: '1px solid #ddd',
                                  borderRadius: '5px',
                                  overflow: 'hidden',
                                  height: '240px',
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/*end card*/}
            </div>
            {/*end col*/}
            <div className='col-xxl-3'>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='card-title fw-semibold mb-0'>
                      Ticket Details
                    </h6>

                    <Link
                      to={`/editUnitTicket/${ticketData.ticket_id}`}
                      className='btn btn-info btn-sm mt-2'
                    >
                      <i className='ri-pencil-line'></i>
                      Edit
                    </Link>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='table-responsive table-card'>
                    <table className='table table-borderless align-middle mb-0'>
                      <tbody>
                        <tr>
                          <td className='fw-medium'>Ticket</td>
                          <td>
                            <span id='t-no'>{ticketData.ticket_number}</span>{' '}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Property</td>
                          <td id='t-client'>{property.property_alias_name}</td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Unit</td>
                          <td>{unit.unit_name}</td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Assigned To:</td>
                          <td>
                            <div className='avatar-group'>
                              {usersAssignedToTicket &&
                                usersAssignedToTicket.length > 0 &&
                                usersAssignedToTicket.map((user, index) => (
                                  <a
                                    key={index}
                                    href='javascript:void(0);'
                                    className='avatar-group-item material-shadow'
                                    data-bs-toggle='tooltip'
                                    data-bs-placement='top'
                                    data-bs-trigger='hover'
                                    data-bs-original-title='Erica Kernan'
                                  >
                                    <img
                                      src={profileImg}
                                      alt={`${user.first_name} image`}
                                      className='rounded-circle avatar-xs'
                                    />
                                  </a>
                                ))}

                              <Link
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                className='avatar-group-item material-shadow'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                data-bs-original-title='Add Members'
                              >
                                <div className='avatar-xs'>
                                  <div className='avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary'>
                                    +
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Status:</td>
                          <td>
                            <Select
                              name='ticket_status_id'
                              options={ticketStatuses}
                              value={ticketStatusesSelected}
                              onChange={handleTicketStatusChange}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Priority</td>
                          <td>
                            <Select
                              name='ticket_priority_id'
                              options={ticketPriorities}
                              value={ticketPrioritiesSelected}
                              onChange={handleTicketPriorityChange}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Create Date</td>
                          <td id='c-date'>
                            {new Date(ticketData.created_at).toLocaleString(
                              'en-US',
                              { year: 'numeric', month: 'long', day: 'numeric' }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Last Updated</td>
                          <td id='d-date'>
                            {' '}
                            {new Date(ticketData.updated_at).toLocaleString(
                              'en-US',
                              { year: 'numeric', month: 'long', day: 'numeric' }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Last Activity</td>
                          <td>
                            {ticketData?.updated_at &&
                              formatDistanceToNow(
                                new Date(ticketData.updated_at)
                              )}{' '}
                            ago
                          </td>
                        </tr>
                        <tr hidden>
                          <td className='fw-medium'>Tags</td>
                          <td className='hstack text-wrap gap-1'>
                            <span className='badge bg-primary-subtle text-primary'>
                              Admin
                            </span>
                            <span className='badge bg-primary-subtle text-primary'>
                              UI
                            </span>
                            <span className='badge bg-primary-subtle text-primary'>
                              Dashboard
                            </span>
                            <span className='badge bg-primary-subtle text-primary'>
                              Design
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end card-body*/}
              </div>
              {/*end card*/}
              {/* All the attachments in the ticket shows here to download but the image or video appear in the comments*/}
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='card-title fw-semibold mb-0'>
                      File Attachments
                    </h6>
                    <Link
                      className='btn btn-info btn-sm mt-2'
                      data-bs-toggle='modal'
                      data-bs-target='#attachFileModal'
                      onClick={() => {
                        setShowAddAttachmentModal(true);
                      }}
                    >
                      <i className='ri-attachment-line'></i>
                      Add
                    </Link>
                  </div>
                </div>
                <div
                  className='card-body'
                  style={{ maxHeight: '230px', overflowY: 'auto' }}
                >
                  {isAttachmentsLoading ? (
                    <div className='text-center'>
                      <div
                        className='spinner-border text-primary'
                        role='status'
                      >
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    ticketData.ticket_media_urls &&
                    ticketData.ticket_media_urls.length > 0 &&
                    ticketData.ticket_media_urls.map((attachment, index) => (
                      <div
                        key={index}
                        className='d-flex align-items-center border border-dashed p-2 rounded mb-2'
                      >
                        <FilePreview
                          file_name={attachment.file_name}
                          file_type={attachment.file_type}
                          url={attachment.url}
                        />

                        <div className='flex-grow-1 ms-3'>
                          <h6 className='mb-1'>
                            <Link
                              onClick={() => {
                                setItemToPreview(attachment);
                                setShowPreviewModal(true);
                              }}
                            >
                              {attachment.file_name
                                ? attachment.file_name.length > 35
                                  ? `${attachment.file_name.substring(
                                      0,
                                      35
                                    )}...`
                                  : attachment.file_name
                                : 'No File Name'}
                            </Link>
                          </h6>
                          <small className='text-muted'>
                            {attachment.file_size
                              ? attachment.file_size_formatted
                              : 'No Size'}
                          </small>
                        </div>
                        <div className='hstack gap-3 fs-16'>
                          {/*TODO: next and the click on image (Modal) View Button */}
                          <Link
                            onClick={() => {
                              setItemToPreview(attachment);
                              setShowPreviewModal(true);
                            }}
                            className='text-muted'
                          >
                            <i className='ri-eye-line'></i>
                          </Link>
                          {/*Download Button */}
                          <a
                            href={attachment.url}
                            download={attachment.file_name}
                            target='_blank'
                            className='text-muted'
                          >
                            <i className='ri-download-2-line'></i>
                          </a>
                          {/*Delete Button */}
                          <Link
                            onClick={() => {
                              setItemToDelete(attachment);
                              setShowConfirm(true);
                            }}
                            className='text-muted'
                          >
                            <i className='ri-delete-bin-line'></i>
                          </Link>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketDetail;
