import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Joi from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Input from '../../../reusable/form/Input';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../constants/config.json';
import { getCode } from 'country-list';
import { fetchAllCurrencies } from '../../../../services/systemDefined/currency/sysCurrencyManagement';
import {
  createNewUserDefinedCountry,
  fetchAllCountries,
} from '../../../../services/systemDefined/country/countryManagement';
import {
  createOrUpdatePortfolio,
  getPortfolioByOwnershipId,
  getPortfolioByPortfolioId,
} from '../../../../services/portfolio/portfolioManagement';
import { createOrUpdateProperty } from '../../../../services/property/create/propertyCreationService';
import { updateProperty } from '../../../../services/property/update/propertyUpdateService';
import PageTitleBox from '../../../reusable/title/pageTitleBox';
import {
  fetchAllPropertyTypes,
  fetchAllSubPropertyTypes,
} from '../../../../services/systemDefined/property/sysPropertyTypeManagement';
import { getPropertyByPropertyId } from '../../../../services/property/retrieve/propertyRetrievalService';

import { getOwnershipStructuresByPortfolioId } from '../../../../services/portfolio/ownership/portfolioOwnershipManagement';
import { set } from 'date-fns';
import { get } from 'react-hook-form';
import { selectCustomStyle } from '../../../../styletheme/custom/selectStyle';

import PropertyMultiImageUploader from '../../../reusable/uploader/propertyMultiImageUploader';
import LoaderView from '../../../reusable/loading/loaderView';

const UpdateProperty = ({ isDarkMode }) => {
  const pageTitle = 'Update Property';
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const { propertyId } = useParams();

  const [base64Data, setBase64Data] = useState([]);
  //images to upload for the advertisement
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState({
    property_image_urls: [],
    property_image_uploads: [],
  });
  const [existingImages, setExistingImages] = useState([]);

  const [propertyData, setPropertyData] = useState({
    property_id: '',
    portfolio_id: '',
    currency_id: '',
    property_type_id: '',
    sub_property_type_id: '',
    property_alias_name: '',
    street_address_1: '',
    street_address_2: '',
    city_town: '',
    state_parish: '',
    zip_postal_code: '',
    country_id: '',
    country: '',
    updated_at: '',
    created_at: '',
    property_image_urls: [
      {
        base64_data: '',
        file_name: '',
        unique_file_name: '',
        file_size: 0,
        file_size_formatted: '',
        file_type: '',
        upload_time_stamp: Date.now(),
        url: '',
      },
    ],
    //for uploading the images
    property_image_uploads: [],
  });

  const [owners, setOwners] = useState([
    {
      ownership_structure_id: '',
      portfolio_id: '',
      user_id: '',
      first_name: '',
      last_name: '',
      owner_email: '',
      owner_phone: '',
      ownership_start_date: '',
      ownership_end_date: '',
      ownership_percentage: 0,
      is_controlling_partner: false,
      partnership_type: '',
    },
  ]);

  const [portfolio, setPortfolio] = useState({
    portfolio_id: '',
  });

  const [errors, setErrors] = useState({});
  //set the property_id on save
  const schema = {
    property_id: Joi.string().allow('').optional().label('Property ID'),
    portfolio_id: Joi.string().required().label('Portfolio'), // Changed to required
    currency_id: Joi.string().required().label('Currency'),
    property_type_id: Joi.string().optional().allow('').label('Property Type'),
    sub_property_type_id: Joi.string().required().label('Sub Property Type'),
    property_alias_name: Joi.string().required().label('Property Alias Name'),
    street_address_1: Joi.string().required().label('Street Address 1'),
    street_address_2: Joi.string()
      .optional()
      .label('Street Address 2')
      .allow(''),
    city_town: Joi.string().required().label('City/Town'),
    state_parish: Joi.string().required().label('State/Parish'),
    zip_postal_code: Joi.string().required().label('Zip/Postal Code'),
    country: Joi.string().required().label('Country'),
    country_id: Joi.string().optional().allow('').label('Country ID'),
    updated_at: Joi.date().optional().allow('').label('Updated At'),
    created_at: Joi.date().optional().allow('').label('Created At'),
    property_image_urls: Joi.array()
      .items(
        Joi.object({
          url: Joi.string().required(),
          file_name: Joi.string().required(),
          file_size: Joi.number().required(),
          file_size_formatted: Joi.string().required(),
          file_type: Joi.string().required(),
          upload_time_stamp: Joi.string().required(),
          unique_file_name: Joi.string().required(),
        })
      )
      .optional()
      .label('Property Image URLs'),
    property_image_uploads: Joi.array()
      .items(
        Joi.object({
          base64_data: Joi.string().required(),
          file_name: Joi.string().required(),
          unique_file_name: Joi.string().required(),
          file_type: Joi.string().required(),
          url: Joi.string().required(),
        })
      )
      .optional()
      .label('Property Image Uploads'),
  };

  // Add these helper functions at the top of the component
  const normalizeImageObject = (imageObj) => ({
    base64_data: imageObj.base64_data || '',
    file_name: imageObj.file_name || imageObj.name,
    unique_file_name:
      imageObj.unique_file_name || imageObj.file_name || imageObj.name,
    file_type: imageObj.file_type || imageObj.type,
    url: imageObj.url || '',
  });

  const validateImageObject = (imageObj) => {
    return (
      imageObj &&
      imageObj.file_name &&
      (imageObj.base64_data || imageObj.url) &&
      imageObj.file_type
    );
  };

  //portfolio options
  //will be created from the api here as I am adding new properteries
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  //currency options
  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const handleCurrencyChange = (option) => {
    if (!option) return;

    setSelectedCurrencyOption(option);
    propertyData.currency_id = option.value;
  };

  const getCurrencyOptions = async (_property) => {
    //set currencyOptions
    const response = await fetchAllCurrencies();
    //set currencyOptions
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === 'USD'
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
    }

    //set the current currency
    //find the currency option by property.currency_id
    console.log('Property Currency ID: ', _property.currency_id);
    if (_property && _property.currency_id) {
      const currencyOption = await options.find(
        (option) => option.value === _property.currency_id
      );
      setSelectedCurrencyOption(currencyOption);

      console.log('Selected Currency:', currencyOption);
    }
  };

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);
  //country options
  const [countryOptions, setCountryOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  const handleCountryChange = async (option) => {
    //ensure it is not empty
    if (!option) return;

    setSelectedCountryOption(option);
    propertyData.country = option.value;

    if (option.__isNew__) {
      console.log('User created a new option with value:', option.value);
      //call the api to create the country and tag it as user defined

      //then update the countryOptions
      //createNewUserDefinedCountry
      const countryCode = getCode(option.value);
      if (countryCode) {
        createCountry(option, countryCode);
      } else {
        console.log(
          'Could not find the country code for the specified country. Saving without country code.'
        );
        createCountry(option, '');
      }
    } else {
      console.log('User selected the option:', option.value);
    }
  };

  async function createCountry(option, countryCode) {
    const userId = localStorage.getItem(config.user_id);
    const countryName = option.value;

    var country = {
      country_id: uuidv4(),
      country_name: countryName,
      country_code: countryCode, // This will be undefined if the country code is not found
      is_user_defined: true,
      created_at: new Date(),
      updated_at: new Date(),
      created_by_user_id: userId,
    };

    console.log('Country:', country);

    const updatedCountryList = await createNewUserDefinedCountry(country);

    // If the country was created successfully
    if (updatedCountryList) {
      // Update the countryOptions
      const options = updatedCountryList.map((country) => ({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      }));
      setCountryOptions(options);

      // Select this new country
      setSelectedCountryOption({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      });

      toast.success('New country saved successfully');
    } else {
      // Handle the case where the country could not be saved
      toast.error(
        'New country could not be saved successfully, please try again.'
      );
    }
  }

  const getCountryOptions = async (_property) => {
    const response = await fetchAllCountries();
    const options = response.map((country) => ({
      value: country.country_id,
      label: country.is_user_defined
        ? `${country.country_name} `
        : country.country_name,
      data: country,
    }));
    setCountryOptions(options);

    //set the current country
    //find the country option by property.country_id
    console.log('Property Country ID: ', _property.country_id);
    if (_property && _property.country_id) {
      const countryOption = await options.find(
        (option) => option.value === _property.country_id
      );
      setSelectedCountryOption(countryOption);

      console.log('Selected Country:', countryOption);
    }
  };

  const formattedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: option.data.is_user_defined
      ? `${option.label} (User Defined)`
      : option.label,
  }));

  //property type options
  //propertyTypeOptions
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [selectedPropertyTypeOption, setSelectedPropertyTypeOption] =
    useState(null);

  const handlePropertyTypeChange = (option) => {
    if (!option) return;
    console.log('Selected option:', option);
    console.log(
      'Original sub property type options:',
      originalSubPropertyTypeOptions
    );

    setSelectedPropertyTypeOption(option);

    // Filter the sub property types based on the selected option
    const filtered = originalSubPropertyTypeOptions.filter(
      (subPropertyType) => {
        console.log(
          'Comparing:',
          subPropertyType.data.property_type_id, // change this line
          'and',
          option.value // change this line
        );
        return (
          subPropertyType.data.property_type_id === option.value // change this line
        );
      }
    );
    console.log('Filtered sub property type options:', filtered);
    setSubPropertyTypeOptions(filtered);
    setSelectedSubPropertyTypeOption(null);
  };
  //sub property type options
  //subPropertyTypeOptions
  // Original list of sub property types
  const [originalSubPropertyTypeOptions, setOriginalSubPropertyTypeOptions] =
    useState([{ value: '', label: '', data: {} }]);

  const [subPropertyTypeOptions, setSubPropertyTypeOptions] = useState([
    { value: '', label: '', data: {} },
  ]);

  const [selectedSubPropertyTypeOption, setSelectedSubPropertyTypeOption] =
    useState(null);

  const handleSubPropertyTypeChange = (option) => {
    if (!option) return;
    setSelectedSubPropertyTypeOption(option);
    propertyData.sub_property_type_id = option.value;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(propertyData, schema, options);
    if (!error) return null;

    console.log(`Error: ${error}`);

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    if (!schema[name]) {
      console.error(`No schema defined for ${name}`);
      return `No schema defined for ${name}`;
    }

    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    if (input && input.currentTarget) {
      const { name, value, checked, type } = input.currentTarget;
      const errorMessage = validateProperty({ name, value });

      const newErrors = { ...errors };
      if (errorMessage) {
        newErrors[name] = errorMessage;
      } else {
        delete newErrors[name];
      }

      setPropertyData((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));

      errors[name] = errorMessage;
    } else if (input && input.value) {
      // This is an option from the CreatableSelect component for expense_name
      // Add your specific logic here
    } else if (input && input.label === 'portfolio_id') {
      //alert('Portfolio ID');
      // This is an option from the Select component for expense_category
      // Add your specific logic here
    } else if (input && input.label === 'budget_category_selected_id') {
      // This is an option from the Select component for budget_category_selected_id
      // Add your specific logic here
    }
  };

  const handlePortfolioChange = (option) => {
    if (!option) return;

    console.log('Portfolio changed:', option);

    if (option.__isNew__) {
      console.log('Creating new portfolio group');
      createNewPortfolio(option.label);
    } else {
      setSelectedPortfolioOption(option);
      setPropertyData((prevState) => ({
        ...prevState,
        portfolio_id: option.value,
        // Remove setting portfolio_name here
      }));
    }
  };

  const createNewPortfolio = async (portfolio_name) => {
    const userId = localStorage.getItem(config.user_id);
    const portfolio_id = uuidv4();

    const response = await createOrUpdatePortfolio({
      portfolio_id: portfolio_id,
      ownership_id: userId,
      portfolio_name: portfolio_name,
    });

    if (response) {
      const options = response.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_name,
        data: portfolio,
      }));
      setExistingPortfolioOptions(options);

      const newPortfolio = options.find(
        (portfolio) => portfolio.label === portfolio_name
      );

      if (newPortfolio) {
        setSelectedPortfolioOption(newPortfolio);
        setPropertyData((prevState) => ({
          ...prevState,
          portfolio_id: newPortfolio.value,
          // Remove setting portfolio_name here
        }));
      }

      toast.success('New portfolio saved successfully');
    } else {
      toast.error(
        'New portfolio could not be saved successfully, please try again.'
      );
    }
  };

  const doSubmit = async () => {
    try {
      // Basic validation
      if (!selectedPortfolioOption) {
        toast.error('Please select a portfolio');
        return;
      }

      // Prepare image data
      const imageData = {
        property_image_urls: imagesToUpload.property_image_urls || [],
        property_image_uploads: imagesToUpload.property_image_uploads || [],
      };

      // Prepare property data
      const updatedProperty = {
        ...propertyData,
        portfolio_id: selectedPortfolioOption.value,
        property_type_id: selectedPropertyTypeOption?.value || null,
        country_id: selectedCountryOption?.value || null,
        ...imageData,
      };

      //loading
      setIsLoading(true);

      const result = await updateProperty(updatedProperty);

      if (result) {
        setIsLoading(false);
        toast.success('Property updated successfully');
        navigate(`/property/${propertyId}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating property:', error);
      toast.error('Error updating property');
    }
  };

  const getPropertyTypes = async (_property) => {
    const response = await fetchAllPropertyTypes();

    if (!response) {
      console.log('No property types found');
      return;
    }

    const options = response.map((propertyType) => ({
      value: propertyType.property_type_id,
      label: propertyType.property_type_name,
      data: propertyType,
    }));

    setPropertyTypeOptions(options);

    if (!_property) return;

    const propertyTypeOption = options.find(
      (option) => option.value === _property.property_type_id
    );

    if (propertyTypeOption) {
      setSelectedPropertyTypeOption({
        value: propertyTypeOption.value,
        label: propertyTypeOption.label,
        data: propertyTypeOption.data,
      });
    } else {
      console.warn(
        `No property type option found for property type id ${_property.property_type_id}`
      );
    }
  };

  const getSubPropertyTypes = async (_property) => {
    const response = await fetchAllSubPropertyTypes();

    if (!response) {
      // handle error
      console.log('No sub property types found');
      console.log('Sub Property Types:', response);
      return;
    }

    const options = response.map((subPropertyType) => ({
      value: subPropertyType.sub_property_type_id,
      label: subPropertyType.sub_property_type_name,
      data: subPropertyType,
    }));

    setSubPropertyTypeOptions(options);
    setOriginalSubPropertyTypeOptions(options);

    console.log('Sub Property Types:', options);

    if (!_property) return;

    //find the sub property type option by property.sub_property_type_id
    const subPropertyTypeOption = options.find(
      (option) => option.value === _property.sub_property_type_id
    );

    if (!subPropertyTypeOption) {
      // handle error
      return;
    }

    setSelectedSubPropertyTypeOption({
      value: subPropertyTypeOption.value,
      label: subPropertyTypeOption.label,
      data: subPropertyTypeOption.data,
    });
  };

  const getExistingPortfolios = async (_property) => {
    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log('No portfolios found for this user');
      return;
    }

    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));

    console.log('Existing Portfolios:', options);
    setExistingPortfolioOptions(options);

    // Set the selected portfolio based on the property profile
    const selectedPortfolio = options.find(
      (option) => option.value === _property.portfolio_id
    );
    console.log('Selected Portfolio:', selectedPortfolio);

    if (selectedPortfolio) {
      setSelectedPortfolioOption(selectedPortfolio);
      setPropertyData((prevState) => ({
        ...prevState,
        portfolio_id: selectedPortfolio.value,
        // Remove setting portfolio_name here
      }));
    }
  };

  const getCurrentPropertyProfile = async () => {
    try {
      const property = await getPropertyByPropertyId(propertyId);

      if (!property) return null;

      // Handle existing images
      if (
        property.property_image_urls?.length > 0 ||
        property.property_image_uploads?.length > 0
      ) {
        // Format existing images for display
        const existingImageFiles = property.property_image_urls.map(
          (imageObj) => ({
            name: imageObj.file_name,
            preview: imageObj.url,
            type: imageObj.file_type,
            unique_file_name: imageObj.unique_file_name,
            isExisting: true,
          })
        );

        setSelectedFiles(existingImageFiles);
        setExistingImages(property.property_image_urls);

        // Initialize upload state
        setImagesToUpload({
          property_image_urls: property.property_image_urls || [],
          property_image_uploads: [],
        });
      }

      setPropertyData(property);
      return property;
    } catch (error) {
      console.error('Error fetching property:', error);
      return null;
    }
  };

  //click the unit to see the details and rent the unit

  //get the owners of the property
  const getOwnersOfProperty = async (portfolio_id) => {
    //get the owners of the property
    console.log('Portfolio Id');
    console.log(portfolio_id);
    const owners = await getOwnershipStructuresByPortfolioId(portfolio_id);

    console.log('Owners');
    console.log(owners);
    setOwners(owners);
  };

  const getPortfolio = async (portfolio_id) => {
    //get the owners of the property
    console.log('Portfolio Id');
    console.log(portfolio_id);
    const portfolio = await getPortfolioByPortfolioId(portfolio_id);

    console.log('Portfolio');
    console.log(portfolio);

    setPortfolio(portfolio);

    //call handlePortfolioChange
    /*
    var portfolio_option = {
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
      __isNew__: false,
    };

    handlePortfolioChange(portfolio_option);
    */
  };

  // This useEffect hook is run once when the component mounts. It fetches necessary data for the component
  // such as property types, sub property types, currency options, existing portfolios, country options,
  // and the current property profile.

  const fetchData = async () => {
    const propertyProfile = await getCurrentPropertyProfile();
    await getExistingPortfolios(propertyProfile);
    await getPropertyTypes(propertyProfile);
    await getSubPropertyTypes(propertyProfile);
    await getCurrencyOptions(propertyProfile);
    await getCountryOptions(propertyProfile);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData();
  }, []);

  return (
    <React.Fragment>
      {/* Loader*/}
      {isLoading && <LoaderView />}

      {/* Page content */}
      <div
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
        className='page-content'
      >
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='Property'
            previousPageLink={`/property/${propertyId}`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Update Property
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Portfolio
                            </label>
                            <CreatableSelect
                              name='portfolio_id'
                              options={existingPortfolioOptions}
                              onChange={handlePortfolioChange}
                              isClearable
                              value={selectedPortfolioOption}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>Currency</label>
                            <Select
                              value={selectedCurrencyOption}
                              onChange={handleCurrencyChange}
                              options={currencyOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Property Type
                            </label>
                            <Select
                              value={selectedPropertyTypeOption}
                              onChange={handlePropertyTypeChange}
                              options={propertyTypeOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <label htmlFor='form-grid-category'>
                              Property Sub Type
                            </label>
                            <Select
                              value={selectedSubPropertyTypeOption}
                              onChange={handleSubPropertyTypeChange}
                              options={subPropertyTypeOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='property_alias_name'
                              label='Property Alias Name'
                              type={'text'}
                              value={propertyData?.property_alias_name}
                              onChange={handleChange}
                              error={errors?.property_alias_name}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='street_address_1'
                              label='Street Address 1'
                              type={'text'}
                              value={propertyData?.street_address_1}
                              onChange={handleChange}
                              error={errors?.street_address_1}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='street_address_2'
                              label='Street Address 2 (Optional)'
                              type={'text'}
                              value={propertyData?.street_address_2}
                              onChange={handleChange}
                              error={errors?.street_address_2}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='city_town'
                              label='City/Town'
                              type={'text'}
                              value={propertyData?.city_town}
                              onChange={handleChange}
                              error={errors?.city_town}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='state_parish'
                              label='State/Parish/Province'
                              type={'text'}
                              value={propertyData?.state_parish}
                              onChange={handleChange}
                              error={errors?.state_parish}
                            />
                          </div>
                        </div>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='zip_postal_code'
                              label='Zip/Postal Code'
                              type={'number'}
                              value={propertyData?.zip_postal_code}
                              onChange={handleChange}
                              error={errors?.zip_postal_code}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            {/* <Input
                              name='expense_name'
                              label='Expense Name'
                              value={expenseData.expense_name}
                              onChange={handleChange}
                              error={errors.expense_name}
                            />*/}
                            <label htmlFor='form-grid-category'>Country</label>

                            <CreatableSelect
                              name='country'
                              options={formattedCountryOptions}
                              onChange={handleCountryChange}
                              isClearable
                              value={selectedCountryOption}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        <div className='row gy-3 pt-4'>
                          <div className='col-xxl-3 col-md-6'>
                            <label htmlFor='form-grid-category'>
                              Property Images
                            </label>
                            <PropertyMultiImageUploader
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              base64Data={base64Data}
                              setBase64Data={setBase64Data}
                              imagesToUpload={imagesToUpload}
                              setImagesToUpload={setImagesToUpload}
                              uploadBtnText='Update Selection'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer '>
                      <div className='col-12 d-flex justify-content-between'>
                        <button
                          type='button'
                          className='btn btn-outline-secondary'
                          onClick={() => navigate(`/property/${propertyId}`)}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={validate()}
                          className='btn btn-success'
                          type='submit'
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateProperty;
