import http from "../../services/general/httpService";
import api from "../../constants/config.json";

///portfolio/create_property_units
export async function createPropertyUnits(propertyUnits) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/create_property_units`;
    const {data: result} = await http.post(fullApiEndpoint, propertyUnits);

    return result;
}

///portfolio/get_property_units_by_property_id and it is a post request
export async function getPropertyUnitsByPropertyId(propertyId) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/get_property_units_by_property_id`;
    const {data: result} = await http.post(fullApiEndpoint, {property_id: propertyId});

    return result;
}

//portfolio/update_property_unit
export async function updatePropertyUnit(property_unit_id, property_unit_data) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/update_property_unit`;
    const {data: result} = await http.post(fullApiEndpoint, {
        property_unit_id: property_unit_id,
        property_unit_data: property_unit_data
    });

    return result;
}

///portfolio/get_property_unit_by_property_unit_id"
export async function getPropertyUnitByPropertyUnitId(propertyUnitId) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/get_property_unit_by_property_unit_id`;
    const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: propertyUnitId});

    return result;
}
//TODO NEXT IMPLEMENT THE GUAGE TO WORK PROPERLY AND ADD THE ACTIVE AND RENT TOGGLE CONTROL
//todo: need to implement this on the UI
//set_property_unit_is_active
export async function setPropertyUnitIsActive(propertyUnitId, isActive) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/set_property_unit_is_active`;
    const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: propertyUnitId, is_active: isActive});

    return result;
}

//set_property_unit_is_rented
export async function setPropertyUnitIsRented(propertyUnitId, isRented) {
    const fullApiEndpoint = api.apiEndpoint + `/portfolio/set_property_unit_is_rented`;
    const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: propertyUnitId, is_rented: isRented});

    return result;
}