

// Web Worker setup
export const createImageWorker = () => {
  const workerCode = `
    self.onmessage = function(e) {
      const file = e.data.file;
      const reader = new FileReader();
      reader.onload = function(event) {
        self.postMessage({ id: e.data.id, result: event.target.result });
      };
      reader.readAsDataURL(file);
    }
  `;
  const workerBlob = new Blob([workerCode], { type: 'application/javascript' });
  return URL.createObjectURL(workerBlob);
};
