import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../constants/config.json';

const PropertyUnitCard = ({
  unit,
  defaultHouseImg,
  selectedCurrencyOption,
}) => {
  const isDarkMode = localStorage.getItem(config.is_dark_mode) === 'true';

  return (
    <div className='tw-w-full'>
      <div
        className={`tw-rounded-lg tw-shadow-md tw-border tw-p-6 tw-transition-all tw-duration-300 hover:tw-shadow-lg ${
          isDarkMode
            ? 'tw-bg-gray-800 tw-border-gray-700'
            : 'tw-bg-white tw-border-gray-200'
        }`}
      >
        <div className='tw-flex tw-flex-col tw-space-y-4'>
          <div className='tw-relative'>
            <Link to={`/propertyunit/${unit.property_unit_id}`}>
              <img
                src={
                  unit.property_unit_image_urls &&
                  unit.property_unit_image_urls?.length > 0
                    ? unit.property_unit_image_urls[0].url
                    : defaultHouseImg
                }
                alt={unit.unit_name}
                className='tw-w-full tw-h-48 tw-object-cover tw-rounded-md'
              />
            </Link>
            <div className='tw-absolute tw-top-2 tw-right-2'>
              {unit.is_rented ? (
                <span className='tw-px-2 tw-py-1 tw-text-xs tw-font-semibold tw-rounded-full tw-bg-green-500 tw-text-white'>
                  Rented
                </span>
              ) : (
                <span className='tw-px-2 tw-py-1 tw-text-xs tw-font-semibold tw-rounded-full tw-bg-red-500 tw-text-white'>
                  Vacant
                </span>
              )}
            </div>
          </div>

          <div className='tw-space-y-2'>
            <Link to={`/propertyunit/${unit.property_unit_id}`}>
              <h4
                className={`tw-text-base tw-font-medium hover:tw-text-blue-600 tw-transition-colors ${
                  isDarkMode ? 'tw-text-gray-100' : 'tw-text-gray-900'
                }`}
              >
                {unit.unit_name}
              </h4>
            </Link>

            <div
              className={`tw-grid tw-grid-cols-3 tw-gap-1 ${
                isDarkMode ? 'tw-text-gray-300' : 'tw-text-gray-600'
              }`}
            >
              <div className='tw-flex tw-items-center tw-space-x-1'>
                <i className='bx bx-bed tw-text-base'></i>
                <span className='tw-text-xs'>{unit.number_of_bedrooms}</span>
              </div>
              <div className='tw-flex tw-items-center tw-space-x-1'>
                <i className='bx bx-bath tw-text-base'></i>
                <span className='tw-text-xs'>{unit.number_of_bathrooms}</span>
              </div>
              <div className='tw-flex tw-items-center tw-space-x-1'>
                <i className='bx bx-ruler tw-text-base'></i>
                <span className='tw-text-xs'>{unit.square_footage}</span>
              </div>
            </div>

            <div
              className={`tw-flex tw-items-center tw-space-x-1 ${
                isDarkMode ? 'tw-text-gray-100' : 'tw-text-gray-900'
              }`}
            >
              <i className='bx bx-money tw-text-base'></i>
              <span className='tw-text-sm tw-font-medium'>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency:
                    selectedCurrencyOption?.data?.currency_code || 'USD',
                }).format(unit.market_rent_amount)}{' '}
                {selectedCurrencyOption?.data?.currency_code || 'USD'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyUnitCard;
