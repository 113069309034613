import React from "react";

const PreviewModalContent = ({ itemToPreview, height = "200px" }) => {
  const renderPreviewContent = () => {
    if (!itemToPreview) {
      return "No preview content available";
    }

    try {
      const content =
        typeof itemToPreview === "string"
          ? itemToPreview
          : JSON.stringify(itemToPreview, null, 2);
      const words = content.split(/\s+/);
      const truncatedContent = words
        .slice(0, 200)
        .join(" ")
        .replace(/<img /g, '<img style="max-width: 100%; height: auto;" ');

      return truncatedContent + (words.length > 200 ? "..." : "");
    } catch (error) {
      console.error("Error rendering preview content:", error);
      return "Error displaying preview content";
    }
  };

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: renderPreviewContent(),
      }}
      style={{
        overflow: "auto",
        height: height,
        border: "1px solid #ddd",
        borderRadius: "5px",
      }}
      className="mb-2"
    />
  );
};

export default PreviewModalContent;
