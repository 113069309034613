import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import { baseImageUrl } from "../../../constants/images/defaultImgs";
//import logo from "../../../styletheme/assets/img/brand/logo.png";
//import logo_white from "../../../styletheme/assets/img/brand/logo-white.png";
import config from "../../../constants/config.json";
//import "../../../styletheme/assets/css/menu/navstyles.css";
//import user_img from "../../../styletheme/assets/img/users/6.jpg";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../../../styletheme/logos/gurenter_logo.png";
import minimal_logo from "../../../styletheme/logos/minimal/gurenter-logo-minimal.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import { getCurrentUser } from "../../../services/authentication/auth";
import { useSettings } from "../../../services/authentication/contexts/settings/settingsContext";
const NavigationBar = (props) => {
  const { settings, setSettings } = useSettings();
  const { userProfile } = props;

  const [cartItems, setCartItems] = useState(5);
  const [notifications, setNotifications] = useState(3);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [userData, setUserData] = useState({
    first_name: localStorage.getItem(config.first_name) || "",
    last_name: localStorage.getItem(config.last_name) || "",
    profile_img: localStorage.getItem(config.profile_img) || "",
    email: localStorage.getItem(config.email) || "",
  });

  // Update userData state with a function that can be called from multiple places
  const updateUserData = () => {
    setUserData({
      first_name: localStorage.getItem(config.first_name) || "",
      last_name: localStorage.getItem(config.last_name) || "",
      profile_img: localStorage.getItem(config.profile_img) || "",
      email: localStorage.getItem(config.email) || "",
    });
  };

  // Create a custom event for local storage updates
  const localStorageChanged = new Event("localStorageChanged");

  // Override localStorage setItem and removeItem to trigger our custom event
  const originalSetItem = localStorage.setItem;
  const originalRemoveItem = localStorage.removeItem;

  localStorage.setItem = function (key, value) {
    originalSetItem.apply(this, arguments);
    window.dispatchEvent(localStorageChanged);
  };

  localStorage.removeItem = function (key) {
    originalRemoveItem.apply(this, arguments);
    window.dispatchEvent(localStorageChanged);
  };

  useEffect(() => {
    // Listen for both storage events (other tabs) and our custom event (same tab)
    window.addEventListener("storage", updateUserData);
    window.addEventListener("localStorageChanged", updateUserData);

    // Initial update
    updateUserData();

    // Cleanup
    return () => {
      window.removeEventListener("storage", updateUserData);
      window.removeEventListener("localStorageChanged", updateUserData);
      // Restore original localStorage methods
      localStorage.setItem = originalSetItem;
      localStorage.removeItem = originalRemoveItem;
    };
  }, []);

  const toggleDarkMode = () => {
    setSettings((prevSettings) => {
      const newDarkMode = !prevSettings.isDarkMode;
      // Save to localStorage
      localStorage.setItem(config.is_dark_mode, newDarkMode.toString());
      return {
        ...prevSettings,
        isDarkMode: newDarkMode,
      };
    });
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };
  /*
 <li className="nav-item">
                                        <a href="apps-calendar.html" className="nav-link" data-key="t-calendar"> Calendar </a>
                                    </li>
*/
  const handleLogout = () => {
    // Clear the landlord profile
    if (props.landlordProfile) {
      props.landlordProfile = {};
    }

    // Clear localStorage items
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("profile_img");
    localStorage.removeItem("email");
    localStorage.removeItem("is_dark_mode");
    localStorage.removeItem("number_of_free_trial_days_left");
    localStorage.removeItem("subscription_name");
    localStorage.removeItem("subscription_status");
    localStorage.removeItem("subscription_id");

    // Clear user data state
    setUserData({
      first_name: "",
      last_name: "",
      profile_img: "",
      email: "",
    });
  };

  return (
    <React.Fragment>
      <Navbar
        id="page-topbar"
        collapseOnSelect
        expand="lg"
        bg={settings.isDarkMode ? "dark" : "medium"}
        variant={settings.isDarkMode ? "dark" : "medium"}
        //bg={isDarkMode ? "dark" : "medium"}
        // variant={isDarkMode ? "dark" : "medium"}
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Navbar.Brand as={Link} to="/dashboard" style={{ paddingLeft: "10px" }}>
          <img
            src={logo}
            width="140"
            height="50"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ paddingRight: "10px" }}
        />
        {!getCurrentUser() && (
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ justifyContent: "right" }}
          >
            <Nav className="ml-auto fs-5">
              <Nav.Link as={Link} to="/signup" className="nav-link ">
                Sign Up
              </Nav.Link>
            </Nav>
            <Nav className="ml-auto fs-5">
              <Nav.Link as={Link} to="/" className="nav-link ">
                Login
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}

        {getCurrentUser() && (
          <>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              style={{ justifyContent: "center" }}
            >
              <Nav className="ml-auto fs-5">
                <Nav.Link as={Link} to="/dashboard" className="nav-link ">
                  Dashboard
                </Nav.Link>

                <NavDropdown title="Properties" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/myproperties">
                    My Properties
                  </NavDropdown.Item>
                  <NavDropdown.Item href={"/newproperty"}>
                    Create New Property
                  </NavDropdown.Item>
                  {/*}  <NavDropdown.Item href='pricing.html'>
                    Pricing Packages
                  </NavDropdown.Item>
                  <NavDropdown.Item href='download.html'>
                    Download Page
                  </NavDropdown.Item>
                  <NavDropdown.Item href='projects.html'>
                    Our Projects
                  </NavDropdown.Item>
                  <NavDropdown.Item href='project-details.html'>
                    Project Details
        </NavDropdown.Item>*/}
                </NavDropdown>
                <NavDropdown hidden title="Reports" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/tenantapplication">
                    Return on Investment
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Requests" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/newUnitTicket">
                    Create New Request
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/allTicketsInPortfolio">
                    All Requests
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href='features.html'>
                    Pending Requests
                  </NavDropdown.Item>
                  <NavDropdown.Item href='pricing.html'>
                    Completed Requests
                  </NavDropdown.Item>*/}
                </NavDropdown>
                <NavDropdown title="Contractors" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/contractors">
                    All Contractors
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/createContractor">
                    Create New Contractor
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Contract Templates" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/newContractTemplate">
                    Create New Template
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/contractTemplates">
                    All Templates
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to="/allAdvertisements"
                  className="nav-link "
                >
                  All Advertisements
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse
              style={{
                justifyContent: "right",
                paddingRight: "100px",
              }}
            >
              <Nav
                style={{
                  paddingRight: "2%",
                }}
                className="fs-5"
              >
                <div className="ms-1 header-item d-none d-sm-flex">
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    data-toggle="fullscreen"
                    onClick={toggleFullscreen}
                  >
                    <i className="bx bx-fullscreen fs-22"></i>
                  </button>
                </div>

                <div className="ms-1 header-item d-none d-sm-flex">
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
                    onClick={toggleDarkMode}
                  >
                    <i
                      className={`bx ${
                        settings.isDarkMode ? "bx-sun" : "bx-moon"
                      } fs-22`}
                    ></i>
                  </button>
                </div>
                <div
                  className="dropdown topbar-head-dropdown ms-1 header-item"
                  id="notificationDropdown"
                >
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id="page-header-notifications-dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-bell fs-22"></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger visually-hidden">
                      3<span className="visually-hidden">unread messages</span>
                    </span>
                  </button>
                </div>
                {/*Customer Support */}
                <div
                  className="dropdown topbar-head-dropdown ms-1 header-item"
                  id="notificationDropdown"
                >
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id="page-header-notifications-dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-support fs-22"></i>
                  </button>
                </div>
              </Nav>

              <Nav className="fs-5">
                {userData.profile_img ? (
                  <img
                    className="rounded-circle header-profile-user"
                    src={userData.profile_img}
                    alt="Profile Avatar"
                    style={{ width: "40px", height: "40px" }}
                  />
                ) : (
                  <img
                    className="rounded-circle header-profile-user"
                    src={baseImageUrl}
                    alt="Profile Avatar"
                    style={{ width: "40px", height: "40px" }}
                  />
                )}

                <NavDropdown
                  title={`${
                    (userData.first_name || "").charAt(0).toUpperCase() +
                    (userData.first_name || "").slice(1)
                  } ${
                    (userData.last_name || "").charAt(0).toUpperCase() +
                    (userData.last_name || "").slice(1)
                  }`.trim()}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item className="dropdown-item " disabled>
                    <i className="mdi mdi-email-outline text-muted fs-16 align-middle me-1"></i>
                    <span className="align-middle">{userData.email}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="div">
                    <Link to="/billing">
                      <i className="mdi mdi-receipt text-muted fs-16 align-middle me-1"></i>{" "}
                      <span className="align-middle">
                        Billing{" "}
                        {/*} <b>
                          {capitalizeFirstLetterOfEachWord(subscription.status)}
                        </b>*/}
                      </span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/profile"
                    className="dropdown-item "
                  >
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Messages</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Taskboard</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Help</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">
                      Balance : <b>$5971.67</b>
                    </span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <span className="badge bg-success-subtle text-success mt-1 float-end">
                      New
                    </span>
                    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Settings</span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                    hidden
                  >
                    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Lock screen</span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/logout"
                    className="dropdown-item"
                    onClick={handleLogout}
                  >
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle" data-key="t-logout">
                      Logout
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </React.Fragment>
  );
};

export default NavigationBar;

{
  /*

 <NavDropdown.Item href="about.html">About Us</NavDropdown.Item>
              <NavDropdown.Item href="features.html">
                Features & Addons
              </NavDropdown.Item>
              <NavDropdown.Item href="pricing.html">
                Pricing Packages
              </NavDropdown.Item>
              <NavDropdown.Item href="download.html">
                Download Page
              </NavDropdown.Item>
              <NavDropdown.Item href="projects.html">
                Our Projects
              </NavDropdown.Item>
              <NavDropdown.Item href="project-details.html">
                Project Details
              </NavDropdown.Item>

			  

	{/*
    <div>
      <div classNameName={click ? "main-container" : ""} onClick={() => Close()} />
      <nav classNameName="navbar" onClick={(e) => e.stopPropagation()}>
        <div classNameName="nav-container">
          <NavLink exact to="/" classNameName="nav-logo">
            Gurenter
            <i classNameName="fa fa-code"></i>
          </NavLink>
          <ul classNameName={click ? "nav-menu active" : "nav-menu"}>
            {localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/dashboard"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/myproperties"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    My Properties
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/blog"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Blog
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/contact"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/logout"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Out
                  </NavLink>
                </li>
              </React.Fragment>
            )}

            {!localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/signup"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Up
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Log In
                  </NavLink>
                </li>
              </React.Fragment>
            )}
         
          </ul>
          <div classNameName="nav-icon" onClick={handleClick}>
            <i classNameName={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
        {localStorage.getItem(config.token_key) && (
          <div className="profile-nav-card main-header-profile bg-secondary p-1 main-header-right">
            <div className="d-flex wd-100p">
              <div className="my-auto" styles={{ textAlign: "center !Important" }}>
                <h6 styles={{ fontSize: 12 }}>
                  <b>
                    {landlordProfile.firstName} {landlordProfile.lastName}
                  </b>
                </h6>
                <span>Landlord</span>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>

		*/
}

{
  /*
    <div>
      <div classNameName={click ? "main-container" : ""} onClick={() => Close()} />
      <nav classNameName="navbar" onClick={(e) => e.stopPropagation()}>
        <div classNameName="nav-container">
          <NavLink exact to="/" classNameName="nav-logo">
            Gurenter
            <i classNameName="fa fa-code"></i>
          </NavLink>
          <ul classNameName={click ? "nav-menu active" : "nav-menu"}>
            {localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/dashboard"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/myproperties"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    My Properties
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/blog"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Blog
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/contact"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/logout"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Out
                  </NavLink>
                </li>
              </React.Fragment>
            )}

            {!localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/signup"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Up
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Log In
                  </NavLink>
                </li>
              </React.Fragment>
            )}
         
          </ul>
          <div classNameName="nav-icon" onClick={handleClick}>
            <i classNameName={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
        {localStorage.getItem(config.token_key) && (
          <div className="profile-nav-card main-header-profile bg-secondary p-1 main-header-right">
            <div className="d-flex wd-100p">
              <div className="my-auto" styles={{ textAlign: "center !Important" }}>
                <h6 styles={{ fontSize: 12 }}>
                  <b>
                    {landlordProfile.firstName} {landlordProfile.lastName}
                  </b>
                </h6>
                <span>Landlord</span>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>

		*/
}
