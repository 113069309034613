import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { Upload } from 'lucide-react';
import { toast } from 'react-toastify';
import { createImageWorker } from './webWorker';
import { PreviewFileItem } from './PreviewFileItem';

const FILE_TYPES = { IMAGE: 'image/', PDF: '.pdf' };

const BaseMultiImageUploader = ({
  selectedFiles,
  setSelectedFiles,
  base64Data,
  setBase64Data,
  onFileRemove, // Callback for specialized removal logic
  onUploadComplete, // Callback for specialized upload logic
  uploadBtnText = 'Upload Files',
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const workerRef = useRef();
  const queueRef = useRef([]);
  const dropZoneRef = useRef(null);
  const fileInputId = useMemo(
    () => `file-input-${Math.random().toString(36).slice(2, 11)}`,
    []
  );
  const [processedFiles, setProcessedFiles] = useState(new Set());

  const processQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      const { file, id } = queueRef.current.shift();
      workerRef.current.postMessage({ file, id });
    }
  }, []);

  useEffect(() => {
    const workerUrl = createImageWorker();
    workerRef.current = new Worker(workerUrl);
    workerRef.current.onmessage = (e) => {
      setBase64Data((prev) => [
        ...prev,
        { id: e.data.id, data: e.data.result },
      ]);
      processQueue();
    };
    return () => workerRef.current.terminate();
  }, []);

  const addFiles = useCallback(
    (files) => {
      const generateUniqueId = () => Math.random().toString(36).slice(2, 11);

      const processNewFiles = Array.from(files).map((file) => {
        const uniqueId = generateUniqueId();
        return file.preview
          ? { ...file, uniqueId }
          : Object.assign(file, { uniqueId });
      });

      const newFiles = processNewFiles.filter((newFile) => {
        const fileKey = `${newFile.name}-${newFile.size}`;
        return (
          !processedFiles.has(fileKey) &&
          !selectedFiles.some(
            (existing) =>
              existing.name === newFile.name && existing.size === newFile.size
          )
        );
      });

      if (newFiles.length > 0) {
        const newProcessedFiles = new Set(processedFiles);
        newFiles.forEach((file) => {
          newProcessedFiles.add(`${file.name}-${file.size}`);
        });
        setProcessedFiles(newProcessedFiles);

        setSelectedFiles((prev) => [...prev, ...newFiles]);

        const filesToProcess = newFiles.filter((file) => !file.preview);
        filesToProcess.forEach((file) => {
          queueRef.current.push({ file, id: file.uniqueId });
        });

        if (filesToProcess.length > 0) processQueue();
      }
    },
    [setSelectedFiles, selectedFiles, processQueue, processedFiles]
  );

  const removeFile = useCallback(
    (index, e) => {
      e.preventDefault();

      const fileInput = document.getElementById(fileInputId);
      if (fileInput) fileInput.value = '';

      const fileToRemove = selectedFiles[index];
      if (fileToRemove) {
        setProcessedFiles((current) => {
          const updated = new Set(current);
          updated.delete(`${fileToRemove.name}-${fileToRemove.size}`);
          return updated;
        });
      }

      // Call the specialized removal logic
      onFileRemove(index, fileToRemove, e);

      if (e.type === 'corrupt') {
        toast.warning('Corrupt or invalid image file removed');
      }
    },
    [selectedFiles, fileInputId, onFileRemove]
  );

  const handleDragEvents = useMemo(
    () => ({
      onDragEnter: (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      },
      onDragLeave: (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
      },
      onDragOver: (e) => {
        e.preventDefault();
        e.stopPropagation();
      },
      onDrop: (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        addFiles(e.dataTransfer.files);
      },
    }),
    [addFiles]
  );

  const handleUpload = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const [newImages, existingImages] = [
          selectedFiles.filter((file) => !file.isExisting),
          selectedFiles.filter((file) => file.isExisting),
        ];

        const newImagesToUpload = base64Data
          .filter((item, index) => !!newImages[index])
          .map((item, index) => ({
            base64_data: item.data,
            file_name: newImages[index]?.name || `file-${index}`,
            unique_file_name: newImages[index]?.uniqueId || `file-${index}`,
          }));

        onUploadComplete(newImagesToUpload, existingImages);
        setProcessedFiles(new Set());
      } catch (error) {
        console.error('Error during upload:', error);
        toast.error('Upload failed. Please try again.');
      }
    },
    [base64Data, selectedFiles, onUploadComplete]
  );

  return (
    <div style={{ maxWidth: '500px' }}>
      <div className='card-body'>
        <div
          className={`mb-3 p-4 border rounded ${
            isDragging ? 'border-primary' : 'border-secondary'
          }`}
          {...handleDragEvents}
          ref={dropZoneRef}
        >
          <input
            type='file'
            multiple
            onChange={(e) => addFiles(e.target.files)}
            className='d-none'
            id={fileInputId}
            accept={`${FILE_TYPES.IMAGE}*,${FILE_TYPES.PDF}`}
          />
          <label
            htmlFor={fileInputId}
            className='w-100 text-center d-flex flex-column align-items-center'
          >
            <Upload className='mb-2' size={32} />
            <p className='mb-0'>
              {isDragging
                ? 'Drop files here'
                : 'Click to select images or PDFs, or drag and drop'}
            </p>
          </label>
        </div>
        {selectedFiles.length > 0 && (
          <div className='row row-cols-3 g-2 mb-3'>
            {selectedFiles.map((file, index) => (
              <div
                key={file.uniqueId || `file-${index}-${file.preview}`}
                className='col'
              >
                <PreviewFileItem
                  file={file}
                  index={index}
                  onRemove={removeFile}
                />
              </div>
            ))}
          </div>
        )}
        <button
          onClick={handleUpload}
          disabled={selectedFiles.length === 0}
          className='btn btn-primary w-100 d-flex align-items-center justify-content-center'
        >
          <Upload className='me-2' size={16} />
          {uploadBtnText} ({selectedFiles.length})
        </button>
      </div>
    </div>
  );
};

export default BaseMultiImageUploader;
