import React, { useEffect, useState } from 'react';
import Joi, { unique } from 'joi-browser';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Badge, Button } from 'react-bootstrap';
import {
  createPropertyUnits,
  getPropertyUnitByPropertyUnitId,
  updatePropertyUnit,
} from '../../../../../services/propertyUnits/propertyUnitManagement';
import Input from '../../../../reusable/form/Input';
import PageTitleBox from '../../../../reusable/title/pageTitleBox';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAllCurrencies } from '../../../../../services/systemDefined/currency/sysCurrencyManagement';
import LoaderView from '../../../../reusable/loading/loaderView';
import { set } from 'date-fns';
import PropertyUnitMultiImageUploader from '../../../../reusable/uploader/PropertyUnitMultiImageUploader';
import { devLog } from '../../../../../services/utils/logger';

const UpdatePropertyUnit = ({ isDarkMode }) => {
  //page title
  const pageTitle = 'Update Property Unit';
  const { propertyUnitId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [base64Data, setBase64Data] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState({
    property_unit_image_urls: [],
    property_unit_image_uploads: [],
  });
  const [existingImages, setExistingImages] = useState([]);

  const [propertyUnitsData, setPropertyUnitsData] = useState([]);
  //handle the change of the property unit and creating the list of property units
  const [currentPropertyUnitData, setCurrentPropertyUnitData] = useState({
    property_unit_id: propertyUnitId,
    property_id: '',
    unit_name: '',
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
    property_unit_image_urls: [
      {
        base64_data: '',
        file_name: '',
        unique_file_name: '',
        file_size: 0,
        file_size_formatted: '',
        file_type: '',
        upload_time_stamp: Date.now(),
        url: '',
      },
    ],
    property_unit_image_uploads: [
      {
        base64_data: '',
        file_name: '',
      },
    ],
  });

  //property unit data to edit
  const [propertyUnitDataToEdit, setPropertyUnitDataToEdit] = useState({
    property_unit_id: propertyUnitId,
    property_id: '',
    unit_name: '',
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
    property_unit_image_urls: [
      {
        base64_data: '',
        file_name: '',
        unique_file_name: '',
        file_size: 0,
        file_size_formatted: '',
        file_type: '',
        upload_time_stamp: Date.now(),
        url: '',
      },
    ],
    property_unit_image_uploads: [
      {
        base64_data: '',
        file_name: '',
      },
    ],
  });
  //schema
  const unitSchema = {
    property_unit_id: Joi.string().allow(''),
    property_id: Joi.string().required().label('Property'),
    unit_name: Joi.string().required().label('Unit Name'),
    number_of_bedrooms: Joi.number().required().label('Number of Bedrooms'),
    number_of_bathrooms: Joi.number().required().label('Number of Bathrooms'),
    square_footage: Joi.number().required().label('Square Footage'),
    market_rent_amount: Joi.number().required().label('Market Rent Amount'),
    property_unit_image_urls: Joi.array()
      .items(
        Joi.object({
          url: Joi.string().required(),
          file_name: Joi.string().required(),
          file_size: Joi.number().required(),
          file_size_formatted: Joi.string().required(),
          file_type: Joi.string().required(),
          upload_time_stamp: Joi.string().required(),
          unique_file_name: Joi.string().required(),
        })
      )
      .optional()
      .label('Property Image URLs'),
    property_unit_image_uploads: Joi.array()
      .items(
        Joi.object({
          base64_data: Joi.string().required(),
          file_name: Joi.string().required(),
          unique_file_name: Joi.string().required(),
          file_type: Joi.string().required(),
          url: Joi.string().required(),
        })
      )
      .optional()
      .label('Property Image Uploads'),
  };

  //errors
  const [errors, setErrors] = useState({});

  //validate the form
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(
      currentPropertyUnitData,
      unitSchema,
      options
    );
    console.log('Error:');
    console.log(error);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
      toast.error(item.message);
    }
    return errors;
  };

  //handle the change of the property unit
  const handleChange = ({ currentTarget: input }) => {
    const data = { ...currentPropertyUnitData };
    data[input.name] = input.value;
    setCurrentPropertyUnitData(data);
  };

  //handle the submit of the property unit
  const handleUnitSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    handleSubmit();
  };

  //submit the list of property units to the server
  const handleSubmit = async () => {
    //Prepare image data
    const imageData = {
      property_unit_image_urls: imagesToUpload.property_unit_image_urls || [],
      property_unit_image_uploads:
        imagesToUpload.property_unit_image_uploads || [],
    };

    //prepare the property unit data
    const propertyUnitData = {
      ...currentPropertyUnitData,
      ...imageData,
    };

    //loading state
    setIsLoading(true);
    //submit the list of property units to the server
    console.log('Current Unit to update:');
    console.log(propertyUnitData);

    if (propertyUnitData) {
      //create the property units
      //createPropertyUnits(propertyUnitsData);
      const updatedUnits = await updatePropertyUnit(
        propertyUnitData.property_unit_id,
        propertyUnitData
      );
      console.log('Updated Units:');
      console.log(updatedUnits);
      //if successful, then show a success message
      if (updatedUnits) {
        setIsLoading(false);
        toast.success('Property Units created successfully');

        //navigate to the property units page
        navigate(`/propertyunit/${propertyUnitId}`);
      } else {
        setIsLoading(false);
        toast.warning('Please add property units to the list');
      }
    }
  };

  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: '', label: '', data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const getCurrencyOptions = async () => {
    //set currencyOptions
    const response = await fetchAllCurrencies();
    //set currencyOptions
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === 'USD'
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
    }
  };

  const fetchPropertyUnitData = async () => {
    const currentPropertyUnit = await getPropertyUnitByPropertyUnitId(
      propertyUnitId
    );
    console.log('Current Property Unit:');
    console.log(currentPropertyUnit);

    if (currentPropertyUnit) {
      //handle existing images
      if (
        currentPropertyUnit.property_unit_image_urls?.length > 0 ||
        currentPropertyUnit.property_unit_image_uploads?.length > 0
      ) {
        const existingImagesFiles =
          currentPropertyUnit.property_unit_image_urls.map((imageObj) => ({
            name: imageObj.file_name,
            preview: imageObj.url,
            type: imageObj.file_type,
            uniqueId: imageObj.unique_file_name,
            isExisting: true,
          }));

        setSelectedFiles(existingImagesFiles);
        setExistingImages(currentPropertyUnit.property_unit_image_urls);

        //Initialize upload state
        setImagesToUpload({
          property_unit_image_urls:
            currentPropertyUnit.property_unit_image_urls || [],
          property_unit_image_uploads: [],
        });
      }

      setCurrentPropertyUnitData({
        property_unit_id: currentPropertyUnit.property_unit_id,
        property_id: currentPropertyUnit.property_id,
        unit_name: currentPropertyUnit.unit_name,
        number_of_bedrooms: currentPropertyUnit.number_of_bedrooms,
        number_of_bathrooms: currentPropertyUnit.number_of_bathrooms,
        square_footage: currentPropertyUnit.square_footage,
        market_rent_amount: currentPropertyUnit.market_rent_amount,
        property_unit_image_urls: currentPropertyUnit.property_unit_image_urls,
        property_unit_image_uploads:
          currentPropertyUnit.property_unit_image_uploads,
      });

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    devLog('Property Units Data:');
    devLog('Property Unit ID:');
    devLog(propertyUnitId);

    setIsLoading(true);

    getCurrencyOptions();

    fetchPropertyUnitData();

    //TODO: get the current property portfolio (dont show the ability to add more units just update this one)
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
        className='page-content'
      >
        <div className='container-fluid'>
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle='Property Unit'
            previousPageLink={`/propertyUnit/${propertyUnitId}`}
          />

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>Update Unit</h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <form>
                  <div className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='unit_name'
                              label='Unit Name'
                              type={'text'}
                              value={currentPropertyUnitData.unit_name}
                              onChange={handleChange}
                              error={errors.unit_name}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='number_of_bedrooms'
                              label='Number of Bedrooms'
                              type={'number'}
                              value={currentPropertyUnitData.number_of_bedrooms}
                              onChange={handleChange}
                              error={errors.number_of_bedrooms}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='number_of_bathrooms'
                              label='Number of Bathrooms'
                              type={'number'}
                              value={
                                currentPropertyUnitData.number_of_bathrooms
                              }
                              onChange={handleChange}
                              error={errors.number_of_bathrooms}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='square_footage'
                              label='Square Footage'
                              type={'number'}
                              value={currentPropertyUnitData.square_footage}
                              onChange={handleChange}
                              error={errors.square_footage}
                            />
                          </div>
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              name='market_rent_amount'
                              label='Market Rent Amount'
                              type={'number'}
                              value={currentPropertyUnitData.market_rent_amount}
                              onChange={handleChange}
                              error={errors.market_rent_amount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row gy-3 pt-4'>
                        <div className='col-xxl-3 col-md-6'>
                          <label htmlFor='form-grid-category'>
                            Property Unit Images
                          </label>
                          <PropertyUnitMultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText='Update Selection'
                          />
                        </div>
                      </div>
                      {/* Add to the list*/}
                      <div className='row gy-4 mt-3'>
                        <div className='col-12 d-flex justify-content-between'>
                          <button
                            type='button'
                            className='btn btn-outline-secondary'
                            onClick={() =>
                              navigate(`/propertyunit/${propertyUnitId}`)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type='button'
                            className='btn btn-success'
                            onClick={handleUnitSubmit}
                            //disabled={validate()}
                          >
                            Update Unit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdatePropertyUnit;
