import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import BaseMultiImageUploader from './base/BaseMultiImageUploader';

const PropertyMultiImageUploader = ({
  selectedFiles,
  setSelectedFiles,
  base64Data,
  setBase64Data,
  imagesToUpload,
  setImagesToUpload,
  uploadBtnText = 'Upload Files',
}) => {
  const handleFileRemove = useCallback(
    (index, fileToRemove, e) => {
      if (fileToRemove?.isExisting) {
        const updatedUrls =
          imagesToUpload.property_image_urls?.filter(
            (img) =>
              img &&
              typeof img === 'object' &&
              img.unique_file_name !== fileToRemove.unique_file_name
          ) || [];

        setImagesToUpload({
          ...imagesToUpload,
          property_image_urls: updatedUrls,
        });
      } else {
        const newFileIndex = selectedFiles
          .filter((f) => !f.isExisting)
          .findIndex((_, i) => i === index);
        if (newFileIndex !== -1) {
          setBase64Data((prev) => prev.filter((_, i) => i !== newFileIndex));
          setImagesToUpload((current) => ({
            ...current,
            property_image_uploads: (
              current.property_image_uploads || []
            ).filter((_, i) => i !== newFileIndex),
          }));
        }
      }

      setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    },
    [
      selectedFiles,
      setSelectedFiles,
      setBase64Data,
      setImagesToUpload,
      imagesToUpload,
    ]
  );

  const handleUploadComplete = useCallback(
    (newImagesToUpload, existingImages) => {
      setImagesToUpload((current) => ({
        property_image_urls: current.property_image_urls || [],
        property_image_uploads: newImagesToUpload,
      }));

      toast.info(
        `Selection confirmed: ${existingImages.length} existing ${
          existingImages.length === 1 ? 'file' : 'files'
        } and ${newImagesToUpload.length} new ${
          newImagesToUpload.length === 1 ? 'file' : 'files'
        }`
      );
    },
    [setImagesToUpload]
  );

  return (
    <BaseMultiImageUploader
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      base64Data={base64Data}
      setBase64Data={setBase64Data}
      onFileRemove={handleFileRemove}
      onUploadComplete={handleUploadComplete}
      uploadBtnText={uploadBtnText}
    />
  );
};

export default PropertyMultiImageUploader;
